<template>
  <div v-show="show">
    <transition name="fast-transition">
      <div>
        <div @click="onClickDialogBackground()" class="dialog-container"></div>

        <div class="dialog-window">
          <img v-show="!showInputSingleLine && !showInput" class="dialog-icon" src="./../assets/warning-dialog.svg" />

          <div ref="title" class="dialog-header"></div>
          <div ref="message" class="dialog-message"></div>

          <b-form-textarea v-show="showInput" class="dialog-multiline-input" v-model="inputText"
            :rows="10" :max-rows="10" maxlength=8192></b-form-textarea>
          
          <b-form-input v-show="showInputSingleLine" v-model="inputText" class="dialog-singleline-input" maxLength="32"></b-form-input>

          <div id="dialog-buttons-container">
            <button v-show="cancelIsVisible" @click="onCancel()" class="default-neutral-button" id="dialog-cancel-button">
              <span ref="cancel" id="drop-window-open-btn-label"></span>
            </button>

            <button @click="onProceed()" class="default-nice-button" id="dialog-proceed-button">
              <span ref="proceed" id="drop-window-open-btn-label"></span>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  components: {
  },
  data: function () {
    return {
      proceedCallback: null,
      show: false,
      showInput: false,
      inputText: "",
      cancelIsVisible: true,
      showInputSingleLine: false
    }
  },
  methods: {
    onClickDialogBackground() {
      this.close();
    },
    showDialog(title, message, cancelText, proceedText, proceedCallback) {
      this.proceedCallback = proceedCallback;
      this.$refs.title.innerHTML = title;
      this.$refs.title.className = "dialog-header"
      this.$refs.message.innerHTML = message;
      if (cancelText) {
        this.$refs.cancel.innerHTML = cancelText;
        this.cancelIsVisible = true
      } else {
        this.cancelIsVisible = false
      }
      
      this.$refs.proceed.innerHTML = proceedText;
      this.showInput = false

      this.show = true;
    },
    showDialogWithInput(title, message, defaultInputText, cancelText, proceedText, proceedCallback) {
      this.proceedCallback = proceedCallback;
      this.$refs.title.innerHTML = title;
      this.$refs.title.className = "dialog-header-left"
      this.$refs.message.innerHTML = message;
      this.$refs.cancel.innerHTML = cancelText;
      this.$refs.proceed.innerHTML = proceedText;
      this.showInput = true
      this.showInputSingleLine = false
      this.inputText = defaultInputText
      this.show = true;
    },
    showDialogWithInputSingleLine(title, message, defaultInputText, cancelText, proceedText, proceedCallback) {
      this.proceedCallback = proceedCallback;
      this.$refs.title.innerHTML = title;
      this.$refs.title.className = "dialog-header-left"
      this.$refs.message.innerHTML = message;
      this.$refs.cancel.innerHTML = cancelText;
      this.$refs.proceed.innerHTML = proceedText;
      this.showInputSingleLine = true
      this.showInput = false
      this.inputText = defaultInputText
      
      this.show = true;
    },
    onProceed() {
      if (this.proceedCallback != null) {
        this.proceedCallback(this.inputText);
      }
      this.close()
    },
    onCancel() {
      this.close()
    },
    close() {
      this.show = false;
      this.proceedCallback = null;
    }
  }
}

</script>

<style>
.dialog-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 30;
  top: 0px;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dialog-window {
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 500px;

  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 15px;

  z-index: 31;
}

.dialog-header {
  font-weight: 600;
  font-size: 20px;
  margin-left: 90px;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0);
}

.dialog-header-left {
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0);
}

.dialog-icon {
  width: 60px;
  height: 60px;
  position: absolute;
  margin-top: 20px;
  margin-left: 10px;
}

.dialog-message {
  margin-left: 90px;
  margin-bottom: 20px;
}

#dialog-confirm-button {
  position: absolute;
  right: 20px;
}

#dialog-proceed-button {
  border-radius: 5px !important;
  margin-left: 10px !important;
  background-color: #cf2020 !important;
  box-shadow: 0 16px 24px rgba(207, 32, 32, 0.2), 0 2px 6px rgba(207, 32, 32, 0.12), 0 0 1px rgba(0, 0, 0, 0.04);
}

#dialog-proceed-button:hover {
  background-color: #cb4040 !important;
}

#dialog-cancel-button {
  border-radius: 5px !important;
}

#dialog-buttons-container {
  position: relative;
  height: 35px;
  width: 100%;
  margin: 0 0 0 0;
  text-align: right;
}
.dialog-multiline-input {
  line-height: 18px !important;
  font-size: 15px !important;
  resize: none !important;
  padding-left: 7px !important;
  border-radius: 5px !important;
  margin-bottom: 10px !important;
}

.dialog-singleline-input {
  line-height: 18px !important;
  font-size: 15px !important;
  resize: none !important;
  padding-left: 7px !important;
  border-radius: 5px !important;
  margin-bottom: 10px !important;
}

.dialog-singleline-input:hover {
  background-color: #fafafa !important;
}

.dialog-multiline-input:hover {
  background-color: #fafafa !important;
}
</style>
