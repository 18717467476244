<template>
  <b-row>
    <b-col>
      <b-alert fade v-model="dismissSuccessMessageCountDown" class="position-fixed fixed-top text-center" style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="success" dismissible>
        {{successMessage}}
      </b-alert>      
      <b-alert fade v-model="dismissErrorMessageCountDown" class="position-fixed fixed-top text-center" style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="danger" dismissible>
        {{errorMessage}}
      </b-alert>      

      <div class="account-card-header">{{ $t('analytics.title') }}</div>

      <transition name="analytics-list-fade">
        <div class="account-content-container">
          
          <transition name="fast-transition">
            <SceneAnalyticsGrid v-show="showEntries" ref="sceneAnalyticsGrid"></SceneAnalyticsGrid>
          </transition>

          <div class="account-spinner-container">
            <b-spinner v-if="analyticsLoadingInProcess" variant="dark"></b-spinner>
          </div>
        </div>
      </transition>
    </b-col>
  </b-row>
</template>

<script>

import { API_URL } from "../../const";
import SceneAnalyticsGrid from "./Analytics/SceneAnalyticsGrid.vue";

export default {
  data: function() {
    return {
      analyticsLoadingInProcess: true,
      showEntries: false,
      dismissSuccessMessageCountDown: 0,
      dismissErrorMessageCountDown: 0,
      successMessage: "",
      errorMessage: ""
    }
  },
  components: {
    SceneAnalyticsGrid
  },
  mounted() {
    document.title = this.$t("analytics.title");

    this.downloadEntries()
  },
  updated() {
    document.title = this.$t("analytics.title");
  },
  methods: {
    downloadEntries() {
      this.analyticsLoadingInProcess = true
      this.showEntries = false

      const _this = this
      const headers = this.getCommonHeaders()
      this.axios
        .get(process.env.VUE_APP_API_BASEURL + API_URL.ANALYTICS_MY, {
          params: {},
          headers: headers,
        })
        .then(function (response) {
          _this.analyticsLoadingInProcess = false
          _this.showEntries = true
          
          _this.setEntries(response.data)
        })
        .catch(function (error) {
          console.log("error", error)

          _this.showEntries = false
          _this.analyticsLoadingInProcess = false
        })
    },
    setEntries(entries) {
      this.$refs.sceneAnalyticsGrid.configure(entries)
    }
  }
}
</script>

<style lang="scss">

</style>
