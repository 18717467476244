<template>
  <b-row>
    <b-col>
      <b-alert fade v-model="dismissSuccessMessageCountDown" class="position-fixed fixed-top text-center" style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="success" dismissible>
        {{successMessage}}
      </b-alert>      
      <b-alert fade v-model="dismissErrorMessageCountDown" class="position-fixed fixed-top text-center" style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="danger" dismissible>
        {{errorMessage}}
      </b-alert>      

      <div class="account-card-header">{{ $t('my-collections.title') }}</div>

      <b-button
        class="account-create-item-button"
        id="create-collection-button-mobile"
        :disabled="collectionCreationInProcess"
        @click="onCreateCollection"
        >
          <div id="account-create-item-button-plus" v-if="!collectionCreationInProcess"></div>
          <span class="text-white" v-if="!collectionCreationInProcess">{{ $t('my-collections.create-new') }}</span>
          <b-spinner v-if="collectionCreationInProcess" variant="light"></b-spinner>
      </b-button>

      <div class="my-collections-top-menu">
        <div @click="onClickAll()" id="my-collections-top-menu-all-btn" class="my-collections-top-menu-item my-collections-top-menu-item-on">
          <div></div>
          <div id="my-collections-top-menu-all-label">{{ $t('my-collections.tab-all') }}</div>
        </div>
        <div @click="onClickPublic()" id="my-collections-top-menu-public-btn" class="my-collections-top-menu-item my-collections-top-menu-item-off">
          <div></div>
          <div id="my-collections-top-menu-public-label"></div>
        </div>

        <b-button
          class="account-create-item-button"
          id="create-collection-button"
          :disabled="collectionCreationInProcess"
          @click="onCreateCollection"
          > 
            <div id="account-create-item-button-plus" v-if="!collectionCreationInProcess"></div>
            <span class="text-white" v-if="!collectionCreationInProcess">{{ $t('my-collections.create-new') }}</span>
            <b-spinner v-if="collectionCreationInProcess" variant="light"></b-spinner>
        </b-button>
      </div>
      
      <transition name="my-collection-list-fade">
        <div class="account-content-container">
          <CollectionGrid v-show="showCollectionList && !collectionListLoadingInProcess" ref="grid"></CollectionGrid>
          <div class="account-spinner-container">
            <b-spinner v-if="collectionListLoadingInProcess" variant="dark"></b-spinner>
          </div>
        </div>
      </transition>

    </b-col>
  </b-row>
</template>

<script>

import CollectionGrid from '../CollectionGrid.vue';

export default {
  data: function() {
    return {
      collectionListLoadingInProcess: false,
      showCollectionList: false,
      dismissSuccessMessageCountDown: 0,
      dismissErrorMessageCountDown: 0,
      successMessage: null,
      errorMessage: null,
      collectionCreationInProcess: false
    }
  },
  components: {
    CollectionGrid
  },
  mounted() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["my-collections.title"];
    this.initCollectionList(false)
    this.fetchUserInfo();
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["my-collections"];
  },
  methods: {
    onClickAll() {
      var allMenuItemLabelElement = document.getElementById('my-collections-top-menu-all-btn');
      var publicMenuItemLabelElement = document.getElementById('my-collections-top-menu-public-btn');

      allMenuItemLabelElement.className = "my-collections-top-menu-item my-collections-top-menu-item-on";
      publicMenuItemLabelElement.className = "my-collections-top-menu-item my-collections-top-menu-item-off";

      this.initCollectionList(false)
    },
    onClickPublic() {
      var allMenuItemLabelElement = document.getElementById('my-collections-top-menu-all-btn');
      var publicMenuItemLabelElement = document.getElementById('my-collections-top-menu-public-btn');
      
      allMenuItemLabelElement.className = "my-collections-top-menu-item my-collections-top-menu-item-off";
      publicMenuItemLabelElement.className = "my-collections-top-menu-item my-collections-top-menu-item-on";

      this.initCollectionList(true)
    },
    onCreateCollection() {
      this.collectionCreationInProcess = true
      let _this = this;
      let headers = this.getCommonHeaders();
      let collection = {
        id: 0,
        isPublic: false,
        latitude: 0,
        longitude: 0,
        locales: [{
          name: "New Collection",
          title: "",
          subtitle: "",
          htmlDescription: "",
          info: "",
          cultureId: 1 // eng
        }]
      }

      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/group", collection, {headers: headers}).then(function(response) {
        _this.collectionCreationInProcess = false
        window.location.href = _this.getURLForCollectionEdit(response.data.hash)
      }).catch(function (error) {
        _this.collectionCreationInProcess = false;
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        console.log("error", error);
      });
    },
    fetchUserInfo() {
      var publicMenuItemLabelElement = document.getElementById('my-collections-top-menu-public-label');
      var allMenuItemLabelElement = document.getElementById('my-collections-top-menu-all-label');

      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/user/security", {headers: headers}).then(function(response) {
        allMenuItemLabelElement.innerHTML    = _this.$i18n.t('my-collections.tab-all') + " (" + response.data.currentGroupTotalCount + "/" + response.data.maxGroupsCountTotal + ")"; 
        publicMenuItemLabelElement.innerHTML = _this.$i18n.t('my-collections.tab-public') + " (" + response.data.currentGroupPublicCount + "/" + response.data.maxGroupsCountPublic + ")"; 
      }).catch(function (error) {

      });
    },
    configure(collectionArray, isPublicOnly) {
      if (isPublicOnly) {
        this.$refs.grid.emptyText = "No public collections yet. To make a collection public, turn on <b>Public</b> option"
      } else {
        this.$refs.grid.emptyText = "No collections yet. Press <b>Create New Collection</b> to add one"
      }

      this.$refs.grid.configure(collectionArray, isPublicOnly)
    },
    initCollectionList(isPublicOnly) {
      let _this = this;
      let headers = this.getCommonHeaders();
  
      this.collectionListLoadingInProcess = true;
      this.showCollectionList = false;

      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/myGroups", { params: {excludeMy: true}, headers: headers}).then(function(response) {
        let collectionArray = response.data.value || response.data;
        _this.configure(collectionArray, isPublicOnly)

        _this.collectionListLoadingInProcess = false;
        _this.showCollectionList = true;
      }).catch(function (error) {
        _this.showCollectionList = true;
        _this.collectionListLoadingInProcess = false;
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        console.log("error", error);
      });
    }
  }
}
</script>

<style lang="scss">

.my-collection-item-img {
  background-color: #FAFAFA;
  border-radius: 10px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
  aspect-ratio: 16/9;
  width: 100%;
  min-height: 130px;
  object-fit: cover;
  overflow: hidden;
}
.my-collection-item-amount {
  font-weight: 500;
  right: 5;
  text-align: right;
  position: absolute;
  line-height: 35px;
}
.my-collection-item-name {
  width: 100%;
  padding-right: 45px;
  padding-left: 5px;
  line-height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Helvetica, sans serif;
}

#create-collection-button-mobile {
  @media (min-width: $tablet-width) {
    display: none !important;
  }

  margin-bottom: 10px !important;
}
.my-collection-item-info-container {
  min-height: 30px; 
}
.my-collection-item-container {
  position: relative;
}
.profile-collections-item-separator {
  height: 1px;
  margin-bottom: 10px;
  background-color: #e3e3e3;
}
#profile-collections-spinner {
  left: 50%;
  margin-left: -0.75rem;
  position: absolute;
  margin-top: 30px;
}
.card-body {
  max-height: none !important;
}
#my-collections-spinner-container {
  height: 50px;
}
.my-collection-list-fade-enter-active, .my-collection-list-fade-leave-active {
  transition: opacity .5s;
}
.my-collection-list-fade-enter, .my-collection-list-fade-leave-to {
  opacity: 0;
}
.my-collection-item-link-container { 
  width: 100%;
  aspect-ratio: 16/9;
  display: inline-block;
  position: relative;
}
.my-collections-top-menu {
  border-bottom: 1px solid #bcbcbc;
  margin-bottom: 15px;  
  margin-top: 10px;
  position: relative;
}

.my-collections-top-menu-item {
  display: inline-block;

  font-size: 20px;
  font-weight: 500;
  padding: 0px 10px;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: $mobile-width-only) {
    font-size: 14px !important;
  }
}

.my-collections-top-menu-item-off {
  
}
.my-collections-top-menu-item-off:hover {
  border-bottom: 4px solid #a8d64c44;
}
.my-collections-top-menu-item-on {
  border-bottom: 4px solid #a8d64c;
}

</style>
