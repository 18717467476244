<template>
  <b-row>
    <b-col>
      <b-alert fade v-model="dismissSuccessMessageCountDown" class="position-fixed fixed-top text-center"
        style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="success" dismissible>
        {{ successMessage }}
      </b-alert>
      <b-alert fade v-model="dismissErrorMessageCountDown" class="position-fixed fixed-top text-center"
        style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="danger" dismissible>
        {{ errorMessage }}
      </b-alert>

      <div class="account-header-container">
        <div class="account-card-header">{{ $t('scene-promo.title') }}</div>

        <b-button class="account-create-item-button" id="create-promo-button" :disabled="promoCreationInProcess"
          @click="onCreatePromo">
          <div id="account-create-item-button-plus" v-if="!promoCreationInProcess"></div>
          <span class="text-white" v-if="!promoCreationInProcess">{{ $t('scene-promo.create-new') }}</span>
          <b-spinner v-if="promoCreationInProcess" variant="light"></b-spinner>
        </b-button>
      </div>


      <transition name="promo-list-fade">
        <div class="account-content-container">

          <transition name="fast-transition">
            <ScenePromoGrid v-show="showEntries" ref="scenePromoGrid"></ScenePromoGrid>
          </transition>

          <div class="account-spinner-container">
            <b-spinner v-if="promoLoadingInProcess" variant="dark"></b-spinner>
          </div>
        </div>
      </transition>
    </b-col>
  </b-row>
</template>

<script>

import { API_URL } from "../../const";
import SceneGrid from "./../SceneGrid.vue";
import ScenePromoGrid from "./Promo/ScenePromoGrid.vue";

export default {
  data: function () {
    return {
      promoCreationInProcess: false,
      promoLoadingInProcess: true,
      showEntries: false,
      dismissSuccessMessageCountDown: 0,
      dismissErrorMessageCountDown: 0,
      successMessage: "",
      errorMessage: ""
    }
  },
  components: {
    ScenePromoGrid
  },
  mounted() {
    document.title = this.$t("scene-promo.title");
    this.$refs.scenePromoGrid.onDeleteItem = this.onDeleteItem
    this.downloadEntries()
  },
  updated() {
    document.title = this.$t("scene-promo.title");
  },
  methods: {
    onDeleteItem(promo) {
      let _this = this;
      this.$root.$emit('showDialog', "Warning", "Are you sure you want to delete promo '" + promo.name + "'?", "Cancel", "Delete", function () {
        _this.deletePromo(promo)
      })
    },
    deletePromo(promo) {
      this.promoLoadingInProcess = true
      this.showEntries = false

      let _this = this
      let headers = this.getCommonHeaders()
      let params = {
        hash: promo.hash
      }
      this.axios
        .post(process.env.VUE_APP_API_BASEURL + API_URL.PROMO_DELETE, params, { headers: headers, })
        .then(function (response) {
          _this.promoLoadingInProcess = false
          _this.showEntries = true

          _this.setEntries(response.data)
        })
        .catch(function (error) {
          _this.dismissErrorMessageCountDown = 5
          _this.errorMessage = error.response
            ? error.response.data.cause || error.message
            : "Unknown error"

          _this.promoLoadingInProcess = false
          _this.showEntries = true
          console.log("error", error)
        })
    },
    onCreatePromo() {
      let _this = this
      let headers = this.getCommonHeaders()

      this.axios
        .get(process.env.VUE_APP_API_BASEURL + API_URL.MODELS_MY, {
          params: {},
          headers: headers,
        })
        .then(function (response) {
          let sceneArray = response.data.value || response.data
          _this.showModelPicker(
            sceneArray,
            [], // selected
            1,  // max
          )
        })
        .catch(function (error) {
          _this.dismissErrorMessageCountDown = 5
          _this.errorMessage = error.response
            ? error.response.data.cause || error.message
            : "Unknown error"
          console.log("error", error)
        })
    },
    getPickModelsTitle(selectedAmount, maxAmount) {
      const title =
        this.loc("scene-promo.pick-models") +
        " (" +
        selectedAmount +
        "/" +
        maxAmount +
        ")"
      return title
    },
    showModelPicker(sceneArray, selectedSceneArray, maxSelectedModels) {
      let title = this.getPickModelsTitle(
        selectedSceneArray.length,
        maxSelectedModels,
      )
      const _this = this
      this.$root.showFullscreenPopup({
        item: SceneGrid,
        title,
        applyCallback: _this.onCreatePromoModelsSelected,
        props: {
          selectable: true,
          showPublicLabel: true,
          sceneArray,
          isPublicOnly: false,
          selectedSceneArray,
          maxSelectedModels,
          onSceneSelected: function (grid, item, isSelected) {
            const title = _this.getPickModelsTitle(
              grid.selectedItems ? grid.selectedItems.length : 0,
              maxSelectedModels,
            )
            _this.$root.setFullscreenPopupTitle(title)
          },
        },
      })
    },
    onCreatePromoModelsSelected(sceneGrid) {
      let hashes = sceneGrid.getSelectedSceneHashArray()
      if (!hashes || hashes.length == 0) {
        return
      }

      let _this = this;
      this.$root.$emit('showDialogWithInputSingleLine', this.loc("scene-promo.name-for-promo-hint"), "", "", this.loc("popup-cancel"), this.loc("popup-create"), function (text) {
        _this.onCreatePromoWithHashAndName(hashes[0], text)
      })
    },
    onCreatePromoWithHashAndName(hash, name) {
      this.promoLoadingInProcess = true
      this.showEntries = false

      let params = {
        hash: hash,
        name: name
      }

      const _this = this
      const headers = this.getCommonHeaders()
      this.axios.post(process.env.VUE_APP_API_BASEURL + API_URL.PROMO_CREATE, params, { headers: headers, })
        .then(function (response) {
          _this.promoLoadingInProcess = false
          _this.showEntries = true

          _this.setEntries(response.data)
        })
        .catch(function (error) {
          console.log("error", error)

          _this.showEntries = false
          _this.promoLoadingInProcess = false
        })
    },
    downloadEntries() {
      this.promoLoadingInProcess = true
      this.showEntries = false

      const _this = this
      const headers = this.getCommonHeaders()
      this.axios
        .get(process.env.VUE_APP_API_BASEURL + API_URL.PROMO_MY, {
          params: {},
          headers: headers,
        })
        .then(function (response) {
          _this.promoLoadingInProcess = false
          _this.showEntries = true

          _this.setEntries(response.data)
        })
        .catch(function (error) {
          console.log("error", error)

          _this.showEntries = false
          _this.promoLoadingInProcess = false
        })
    },
    setEntries(entries) {
      this.$refs.scenePromoGrid.configure(entries)
    }
  }
}


</script>

<style lang="scss">
#create-promo-button {
  @media (min-width: $tablet-width) {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0px, -50%);
  }

  @media (max-width: $mobile-width-only) {
    margin-top: 10px;
    display: none !important;
  }
}

#create-promo-button-mobile {
  @media (min-width: $tablet-width) {
    display: none !important;
  }

  margin-bottom: 10px !important;
}

.my-promo-top-menu {
  border-bottom: 1px solid #bcbcbc;
  margin-bottom: 15px;
  margin-top: 10px;
  position: relative;
}
</style>
