import Vue from "vue"
import VueRouter from "vue-router"

//import Home from '../views/Home.vue'

// Fullscreen pages
import Account from "../views/Account/PageAccount.vue"
import NotFound from "../views/Not-found.vue"
import Business from "../views/PageBusiness.vue"
import ConfiguratorPromoPage from "../views/PageConfiguratorPromo.vue"
import SceneEditor from "../views/PageEditor.vue"
import Education from "../views/PageEducation.vue"
import EmailConfirmStatus from "../views/PageEmailConfirmStatus.vue"
import Explore from "../views/PageExplore.vue"
import Login from "../views/PageLogin.vue"
import Museum from "../views/PageMuseum.vue"
import Register from "../views/PageRegister.vue"
import ResetPassword from "../views/PageResetPassword.vue"
import ScenePreview from "../views/PageScenePreview.vue"
import UserPage from "../views/PageUser.vue"
import PrivacyPolicy from "../views/Privacy-policy"
import TermsOfUse from "../views/Terms-of-use.vue"
// ---

import HowTo from "../layouts/HowTo.vue"
import Admin from "../views/Account/Admin.vue"
import Analytics from "../views/Account/Analytics.vue"
import Billing from "../views/Account/Billing.vue"
import MyCollections from "../views/Account/MyCollections.vue"
import MyScenes from "../views/Account/MyScenes.vue"
import MySubscriptions from "../views/Account/MySubscriptions.vue"
import Profile from "../views/Account/Profile.vue"
import Promo from "../views/Account/ScenePromo.vue"
import ChangePassword from "../views/ChangePassword.vue"
import CheckoutStatus from "../views/CheckoutStatus.vue"
import EditCollection from "../views/EditCollection.vue"
import Home from "../views/Home-page.vue"
import Pricing from "../views/Pricing.vue"
import Store from "../views/Store.vue"

import VueMeta from "vue-meta"
import store from "../store"

Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
  },
  {
    path: "/how-to",
    name: "HowTo",
    component: HowTo,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: function (to, from, next) {
      store.commit("logout")
      next("/login")
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/email-confirm-status",
    name: "EmailConfirmStatus",
    component: EmailConfirmStatus,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    children: [
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "my-scenes",
        name: "MyScenes",
        component: MyScenes,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "collection/edit/*",
        name: "EditCollection",
        component: EditCollection,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "my-collections",
        name: "MyCollections",
        component: MyCollections,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "analytics",
        name: "Analytics",
        component: Analytics,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "promo",
        name: "Promo",
        component: Promo,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "checkout/status",
        name: "CheckoutStatus",
        component: CheckoutStatus,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "store",
        name: "Store",
        component: Store,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "my-subscriptions",
        name: "MySubscriptions",
        component: MySubscriptions,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "admin",
        name: "Admin",
        component: Admin,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "billing-history",
        name: "Billing",
        component: Billing,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/privacy-policy",
    name: "Privacy policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-of-use",
    name: "Terms of use",
    component: TermsOfUse,
  },
  {
    path: "/configurator",
    name: "ConfiguratorPromoPage",
    component: ConfiguratorPromoPage,
  },
  {
    path: "/for-business",
    name: "Business",
    component: Business,
  },
  {
    path: "/museums",
    name: "Museums",
    component: Museum,
  },
  {
    path: "/education",
    name: "Education",
    component: Education,
  },
  {
    path: "/explore",
    name: "Explore",
    component: Explore,
    props: true,
  },
  {
    path: "/scene",
    name: "ScenePreview",
    component: ScenePreview,
  },
  {
    path: "/scene/*",
    name: "ScenePreview",
    component: ScenePreview,
  },
  {
    path: "/editor/*",
    name: "SceneEditor",
    component: SceneEditor,
  },
  {
    path: "/profile/*",
    name: "UserPage",
    component: UserPage,
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/*",
    name: "UserPage",
    component: UserPage,
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.isLoggedIn || store.state.token == "") {
      next({
        name: "Login",
      })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
