<template>
  <main class="scene-preview-container catalog in-container">
    <div class="website-page">
      <div class="catalog__bg"></div>
      <div>
        <div id="scene-embed-container">
          <iframe id="scene-embed-container-iframe" ref="modelFrame" title="ARCHITEQUE 3D" allowfullscreen
            mozallowfullscreen="true" webkitallowfullscreen="true" frameborder="0"
            style="margin: 0px; height: 100%; width: 100%"></iframe>
        </div>
      </div>

      <ScenePreviewBottomMenu ref="sceneBottomMenu"></ScenePreviewBottomMenu>

      <div id="scene-embed-description" v-linkified v-html="descriptionRaw"></div>

      <div v-show="showToAppButton" class="scene-preview-open-in-app-container">
        <a class="to-app-button" @click="goToApp">
          <span class="to-app-button-label">{{
        $t("scene-preview.open-in-app-button")
      }}</span>
        </a>
        <div class="scene-preview-open-in-app-hint">
          {{ $t("scene-preview.open-in-app-explanation") }}
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import ScenePreviewBottomMenu from "./ScenePreviewBottomMenu.vue";

export default {
  components: {
    ScenePreviewBottomMenu
  },
  data: function () {
    return {
      sceneHash: "",
      descriptionRaw: "",
      showShare: false,
      showToAppButton: false,
      qrPath: "/qr.png",
      mode: null,
      model: null,
    }
  },
  computed: {},
  created() {
    document.title =
      this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "3d-preview-title"
      ]
  },
  updated() { },
  mounted() {
    var containerFrame = this.$refs.modelFrame
    var sceneNameElement = document.getElementById("scene-embed-name")
    var sceneUserName = document.getElementById("scene-embed-user-name")
    var sceneEmbedDescription = document.getElementById(
      "scene-embed-description",
    )
    var sceneUserAvatar = document.getElementById("scene-embed-user-avatar")
    var avatarLinkElement = document.getElementById("scene-embed-avatar-link")

    window.onmessage = this.onWindowMessage

    this.$refs.sceneBottomMenu.onShare = this.onShare
    this.$refs.sceneBottomMenu.onShowMapClick = this.onShowMapClick

    var sceneHash = this.$route.query.hash
    var conf = this.$route.query.conf
    if (sceneHash == null || sceneHash.length == 0) {
      var path = this.$router.currentRoute.path
      sceneHash = path.substring(path.lastIndexOf("/") + 1)
    }
    this.sceneHash = sceneHash

    let _this = this
    let headers = this.getCommonHeaders()
    this.axios
      .get(process.env.VUE_APP_API_BASEURL + "/api/v1/models/hash", {
        params: { hash: sceneHash },
        headers: headers,
      })
      .then(function (response) {
        var model = response.data

        /*
        if (model.enableConfigurator && model.flags.includes(MODEL_FLAG.HAS_CONFIGURATOR)) {
          webURL = process.env.VUE_APP_WEBSITE_URL + "/archweb_conf.html?"
          webURL += "version=" + process.env.VUE_APP_ARCHWEB_VERSION
          webURL += "&scene=" + sceneHash
          webURL += "&preset=web-conf"
          webURL += "&transparent=0"
          
          if (process.env.NODE_ENV === "development") {
            webURL += "&allowCDN=0"
            webURL += "&resourcesURL=" + process.env.VUE_APP_STATIC_URL
            webURL += "&api=" + process.env.VUE_APP_API_BASEURL
            webURL += "&website-url=" + process.env.VUE_APP_WEBSITE_URL
          }
        } else {
          */
          // Construct embedded scene url for iframe
          var webURL =
            _this.getArchwebURL(true) +
            "allowScale=1&uiStyle=dark&allowMoveCameraSideways=1&allow-fullscreen=1&annotations-toggle=1&show-engine-version=1&show-logo=0&scene=" +
            sceneHash +
            "&token=" +
            _this.$store.state.token
          webURL +=
            "&website-url=" + encodeURIComponent(process.env.VUE_APP_WEBSITE_URL)

          // Pass custom server api url in development mode for testing/debugging
          if (process.env.NODE_ENV === "development") {
            webURL += "&debugInfo=1"
            //webURL += "logEvents=1";
            webURL += "&allowCDN=0"
            webURL +=
              "&api=" + encodeURIComponent(process.env.VUE_APP_API_BASEURL)
          }
          if (conf && conf.length > 0) {
            webURL += "&conf=" + conf
          }
          
        //}

        containerFrame.src = webURL
        containerFrame.onload = _this.onLoadFrame

        var locale = _this.getLocaleForModel(model)

        var sceneName = locale.name
        sceneName = _this.cleanString(sceneName)

        document.title = sceneName + " - " + model.user.name

        var description = _this.fixDescriptionStringForHTML(locale.description)
        _this.descriptionRaw = description

        // Update tags
        _this.$root.$refs.Header.tagTitle = sceneName
        _this.$root.$refs.Header.tagImage =
          process.env.VUE_APP_API_BASEURL +
          "/api/v1/files/imageByModelHash?modelHash=" +
          sceneHash +
          "&fileName=photo_square.jpeg&rand=" +
          Math.random().toString()
        _this.$root.$refs.Header.updateTags()

        let os = _this.getMobileOperatingSystem()
        if (os == "Android" || os == "iOS") {
          _this.showToAppButton = true
        }

        _this.$refs.sceneBottomMenu.configureModel(model)
        _this.model = model
      })
      .catch(function (error) {
        console.log("error: " + error)
      })
  },
  methods: {
    getMobileOperatingSystem: function () {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) {
        return "Android"
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS"
      }

      if (
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0) ||
        navigator.platform === "iPad"
      ) {
        return "iOS"
      }

      return "unknown"
    },
    goToApp: function () {
      let os = this.getMobileOperatingSystem()

      let _this = this
      setTimeout(function () {
        if (os == "Android") {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.architeque.android.app"
        } else if (os == "iOS") {
          // after iOS ~10 it is impossible to gracefully handle app absense
          // so we do it via universal links below
          //window.location.href = "https://itunes.apple.com/app/apple-store/id1447699048";
        } else {
          window.location.href = "https://www.ar-chiteque.com"
        }
      }, 200)

      //if (os == "iOS") {
      // Plan is:
      // add appstore header which will be handled on server side
      // in that case it will be redirectred to appstore html which will redirect to app store page
      window.location = this.generateSceneLink(this.sceneHash, false) + "?appstore=1"
      //} else {
      //  window.location = "architeque://www.ar0.app/c/" + this.sceneHash;
      //}
    },
    getLocaleForModel(model) {
      var userLang = (navigator.language || navigator.userLanguage).substring(
        0,
        2,
      )
      var localeId = 1 // en
      if (userLang == "ru") {
        localeId = 2
      }
      var locale = null
      var defaultLocale = null
      for (let i = 0; i < model.locales.length; i++) {
        var l = model.locales[i]
        if (l.cultureId == 1 /*en*/) {
          defaultLocale = l
        }
        if (l.cultureId == localeId) {
          locale = l
          break
        }
      }
      if (locale == null) {
        locale = defaultLocale
      }

      return locale
    },
    onShare() {
      this.$root.$emit("showShareWindow", this.sceneHash)
    },
    onLoadEngine(engine) {
      this.archengine = engine
      Vue.prototype.$archengine = this.archengine

      this.updateShowShare()
    },
    updateShowShare() {
      if (this.model) {
        this.$refs.sceneBottomMenu.configureModel(this.model)
      }
    },
    onWindowMessage(e) {
      if (
        Object.hasOwn(e.data, "event") &&
        e.data.event == "archengine_loaded"
      ) {
        var containerFrame = document.getElementById(
          "scene-embed-container-iframe",
        )
        if (containerFrame) {
          var iframeDocument =
            containerFrame.contentDocument ||
            containerFrame.contentWindow.document
          let engine = iframeDocument.archengine
          this.onLoadEngine(engine)
        }
      }
    },
    async onShowMapClick() {
      this.showSceneMapPopup(this.model)
    }
  },
}
</script>

<style lang="scss">
#scene-embed-info-container {
  margin-top: 3px;
}

#scene-embed-name-container {
  padding: 15px 0px 15px 20px;

  @media (max-width: $mobile-width-only) {
    padding: 10px 0px 10px 15px;
  }
}

#scene-embed-user-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 20px;
}

#scene-embed-user-container {
  display: flex;
}

#scene-embed-description {
  padding: 10px 0px;
  text-align: justify;
  line-height: 1.4;
  font-family:
    Helvetica,
    sans serif;
}

#scene-embed-name {
  font-size: 12pt;
  line-height: 1;
  color: white !important;
  text-shadow: 0 0 5px var(--color-black-a80, rgba(0, 0, 0, 0.8));

  @media (max-width: $mobile-width-only) {
    font-size: 10pt;
  }
}

#scene-embed-user-name {
  font-size: 10pt;
  color: white !important;
  text-shadow: 0 0 5px var(--color-black-a80, rgba(0, 0, 0, 0.8));

  @media (max-width: $mobile-width-only) {
    font-size: 8pt;
  }
}

#scene-embed-info {
  display: flex;
  position: absolute;
}

#scene-embed-container {
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;

  max-height: calc(100vh - 200px);

  @media (min-width: $desktop-width) {
    max-height: calc(100vh - 135px);
  }

  @media (max-width: $mobile-width-only) {
    aspect-ratio: 3/4;
  }
}

#scene-embed-container iframe {
  background-color: #ededed;
}

.to-app-button {
  display: block;

  font-weight: 600 !important;
  font-size: 18;
  color: #ffffff !important;
  text-decoration: none !important;

  background: #5ab5ba !important;
  border-radius: 25px;
  cursor: pointer;

  user-select: none;
  text-align: center;
  width: 100%;
  line-height: 50px;
  height: 50px;
  margin-top: 10px;

  box-shadow:
    0 16px 24px rgba(90, 181, 186, 0.2),
    0 2px 6px rgba(90, 181, 186, 0.12),
    0 0 1px rgba(0, 0, 0, 0.04);
  position: relative;
}

.to-app-button-label {
  position: relative;
}

.to-app-button:active {
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
}

#scene-embed-container-iframe {
  border-radius: 3px;
}

.scene-embed-logo-container {
  position: absolute;
  bottom: 10;
  left: 10;
  width: 40;
}

.scene-preview-open-in-app-container {
  display: block;
  margin: 20px auto auto auto;
  text-align: center;

  @media (min-width: $tablet-width) {
    max-width: 320px;
  }
}

.scene-preview-open-in-app-hint {
  text-align: center;
  max-width: 320px;
  line-height: 1.3;
  color: #343434;
  font-weight: 500;
  margin: 5px auto auto auto;
  font-size: 16px;
}

.scene-preview-container {
  max-width: 1720px !important;
}

.model-preview__popup.fullscreen-popup {
  @media (min-width: $tablet-width) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .fullscreen-popup-outer {
    max-width: $desktop-width;
  }
}
</style>
