<template>
  <div>
    <div>
      <div id="scene-embed-container">
        <iframe
          id="scene-embed-container-iframe"
          ref="modelFrame"
          title="ARCHITEQUE 3D"
          allowfullscreen
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          frameborder="0"
          style="margin: 0px; height: 100%; width: 100%"
        ></iframe>
      </div>
    </div>
    
    <ScenePreviewBottomMenu ref="sceneBottomMenu"></ScenePreviewBottomMenu>

    <div id="scene-embed-description" v-linkified v-html="descriptionRaw"></div>

  </div>
</template>

<script>
import Vue from "vue";
import { API_URL } from "../../const";
import ScenePreviewBottomMenu from "./../ScenePreviewBottomMenu.vue";
import { IRFAME_QUERY, QR_PATH } from "./const";

export default {
  components: {
    ScenePreviewBottomMenu
  },
  props: {
    hash: {
      default: "",
      type: String,
    },
  },
  data: function () {
    return {
      sceneHash: this.hash,
      descriptionRaw: "",
      showShare: false,
      showToAppButton: false,
      qrPath: QR_PATH,
      model: null,
    }
  },
  mounted() {
    window.onmessage = this.onWindowMessage

    // let sceneHash = this.$route.query.hash
    if (this.sceneHash == null || this.sceneHash.length == 0) {
      const path = this.$router.currentRoute.path
      this.sceneHash = path.substring(path.lastIndexOf("/") + 1)
    }

    this.$refs.sceneBottomMenu.onShare = this.onShare
    this.$refs.sceneBottomMenu.onShowMapClick = this.onShowMapClick
    this.$refs.sceneBottomMenu.enableMap = false
    
    let _this = this
    let headers = this.getCommonHeaders()
    this.axios
      .get(process.env.VUE_APP_API_BASEURL + API_URL.MODELS_HASH, {
        params: { hash: _this.sceneHash },
        headers: headers,
      })
      .then(function (response) {
        const model = response.data

        // Construct embedded scene url for iframe
        let webURL =
          _this.getArchwebURL(true) +
          IRFAME_QUERY +
          _this.sceneHash +
          "&token=" +
          _this.$store.state.token
        webURL +=
          "&website-url=" + encodeURIComponent(process.env.VUE_APP_WEBSITE_URL)

        // Pass custom server api url in development mode for testing/debugging
        if (process.env.NODE_ENV === "development") {
          webURL += "&debugInfo=1"
          //webURL += "logEvents=1";
          webURL += "&allowCDN=0"
          webURL +=
            "&api=" + encodeURIComponent(process.env.VUE_APP_API_BASEURL)
        }
        const containerFrame = document.getElementById(
          "scene-embed-container-iframe",
        )

        containerFrame.src = webURL
        containerFrame.onload = _this.onLoadFrame

        const locale = _this.getLocaleForModel(model)

        let sceneName = locale.name
        sceneName = _this.cleanString(sceneName)

        document.title = sceneName + " - " + model.user.name

        const description = _this.fixDescriptionStringForHTML(
          locale.description,
        )
        _this.descriptionRaw = description

        _this.$refs.sceneBottomMenu.configureModel(model)

        _this.model = model
      })
    // .catch(function (error) {
    //   console.log("error: " + error)
    //   // _this.$router.push({ name: "NotFound" })
    // })
  },
  methods: {
    getMobileOperatingSystem: function () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) {
        return "Android"
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS"
      }

      if (
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0) ||
        navigator.platform === "iPad"
      ) {
        return "iOS"
      }

      return "unknown"
    },
    goToApp: function () {
      let os = this.getMobileOperatingSystem()

      let _this = this
      setTimeout(function () {
        if (os == "Android") {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.architeque.android.app"
        } else if (os == "iOS") {
          // after iOS ~10 it is impossible to gracefully handle app absense
          // so we do it via universal links below
          //window.location.href = "https://itunes.apple.com/app/apple-store/id1447699048";
        } else {
          window.location.href = "https://www.ar-chiteque.com"
        }
      }, 200)

      //if (os == "iOS") {
      // Plan is:
      // add appstore header which will be handled on server side
      // in that case it will be redirectred to appstore html which will redirect to app store page
      window.location = this.generateSceneLink(this.sceneHash, false) + "?appstore=1"
      //} else {
      //  window.location = "architeque://www.ar0.app/c/" + this.sceneHash;
      //}
    },
    humanFileSize(bytes, si = false, dp = 0) {
      const thresh = si ? 1000 : 1024

      if (Math.abs(bytes) < thresh) {
        return bytes + " B"
      }

      const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      )

      return bytes.toFixed(dp) + "" + units[u]
    },
    getLocaleForModel(model) {
      const userLang = (navigator.language || navigator.userLanguage).substring(
        0,
        2,
      )
      let localeId = 1 // en
      if (userLang == "ru") {
        localeId = 2
      }
      let locale = null
      let defaultLocale = null
      for (let i = 0; i < model.locales.length; i++) {
        const l = model.locales[i]
        if (l.cultureId == 1 /*en*/) {
          defaultLocale = l
        }
        if (l.cultureId == localeId) {
          locale = l
          break
        }
      }
      if (locale == null) {
        locale = defaultLocale
      }

      return locale
    },
    onShare() {
      this.$root.$emit("showShareWindow", this.sceneHash)
    },
    onLoadEngine(engine) {
      this.archengine = engine
      Vue.prototype.$archengine = this.archengine

      this.updateShowShare()
    },
    updateShowShare() {
      if (this.model) {
        this.$refs.sceneBottomMenu.configureModel(this.model)
      }
    },
    onWindowMessage(e) {
      if (
        Object.hasOwn(e.data, "event") &&
        e.data.event == "archengine_loaded"
      ) {
        const containerFrame = document.getElementById(
          "scene-embed-container-iframe",
        )
        const iframeDocument =
          containerFrame.contentDocument ||
          containerFrame.contentWindow.document
        let engine = iframeDocument.archengine
        this.onLoadEngine(engine)
      }
    },
    async onShowMapClick() {
      this.showSceneMapPopup(this.model)
    },
  },
}
</script>

<style lang="scss">
#scene-embed-downloads-stat {
  display: flex;
}
#scene-embed-triangles-stat {
  font-weight: 500;
  display: flex;
  margin-right: 10px;
}
#scene-embed-size-stat {
  font-weight: 500;
  margin-right: 10px;
  display: flex;
}
#scene-embed-downloads-stat {
  font-weight: 500;
  margin-right: 10px;
  display: flex;
}
#scene-embed-downloads-stat-icon {
  height: 100%;
  width: 25px;
  margin-right: 2px;
}
#scene-embed-triangles-stat-icon {
  height: 100%;
  width: 17px;
  margin-right: 3px;
}
#scene-embed-stat-container {
  line-height: 40px;
  display: flex;
  flex-wrap: wrap;
}
#scene-embed-menu-panel {
  border-bottom: 1px solid #d8d8d8;
  position: relative;
}
#scene-embed-info-container {
  margin-top: 3px;
}
#scene-embed-name-container {
  padding: 15px 0px 15px 20px;
  @media (max-width: $mobile-width-only) {
    padding: 10px 0px 10px 15px;
  }
}
#scene-embed-user-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 20px;
}
#scene-embed-user-container {
  display: flex;
}
#scene-embed-description {
  padding: 10px 0px;
  text-align: justify;
  line-height: 1.4;
  font-family:
    Helvetica,
    sans serif;
}
#scene-embed-name {
  font-size: 12pt;
  line-height: 1;
  color: white !important;
  text-shadow: 0 0 5px const(--color-black-a80, rgba(0, 0, 0, 0.8));
  @media (max-width: $mobile-width-only) {
    font-size: 10pt;
  }
}
#scene-embed-user-name {
  font-size: 10pt;
  color: white !important;
  text-shadow: 0 0 5px const(--color-black-a80, rgba(0, 0, 0, 0.8));
  @media (max-width: $mobile-width-only) {
    font-size: 8pt;
  }
}
#scene-embed-info {
  display: flex;
  position: absolute;
}
#scene-embed-container {
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;

  max-height: calc(100vh - 200px);
  @media (min-width: $desktop-width) {
    max-height: calc(100vh - 135px);
  }

  @media (max-width: $mobile-width-only) {
    aspect-ratio: 3/4;
  }
}
#scene-embed-container iframe {
  background-color: #ededed;
}
.to-app-button {
  display: block;

  font-weight: 600 !important;
  font-size: 18;
  color: #ffffff !important;
  text-decoration: none !important;

  background: #5ab5ba !important;
  border-radius: 25px;
  cursor: pointer;

  user-select: none;
  text-align: center;
  width: 100%;
  line-height: 50px;
  height: 50px;
  margin-top: 10px;

  box-shadow:
    0 16px 24px rgba(90, 181, 186, 0.2),
    0 2px 6px rgba(90, 181, 186, 0.12),
    0 0 1px rgba(0, 0, 0, 0.04);
  position: relative;
}
.to-app-button-label {
  position: relative;
}
.to-app-button:active {
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
}

#scene-embed-container-iframe {
  border-radius: 3px;
}
.scene-embed-logo-container {
  position: absolute;
  bottom: 10;
  left: 10;
  width: 40;
}
.scene-stat-separator {
  color: #d8d8d8;
  margin-right: 10px;
}
.scene-preview-open-in-app-container {
  display: block;
  margin: 20px auto auto auto;
  text-align: center;
  @media (min-width: $tablet-width) {
    max-width: 320px;
  }
}
.scene-preview-open-in-app-hint {
  text-align: center;
  max-width: 320px;
  line-height: 1.3;
  color: #343434;
  font-weight: 500;
  margin: 5px auto auto auto;
  font-size: 16px;
}
.model-preview-share-button {
  color: black !important;
  font-weight: 500;
  position: relative;
  height: 100%;
  padding-left: 21px;
}
.model-preview-share-button:hover {
  text-decoration: underline;
}
.scene-embed-share-button-container {
  display: flex;
  right: 5px;
  top: 8px;
  cursor: pointer;
}
.model-preview-share-button::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 10px;
  display: block;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-image: url("../../assets/share_black.svg");
}
.scene-preview-container {
  max-width: 1480px !important;
}

.scene-embed-map-button-container {
  @extend .scene-embed-share-button-container;
}

.model-preview-map-button {
  color: black !important;
  font-weight: 500;
  height: 100%;
}

.model-preview__popup.fullscreen-popup {
  @media (min-width: $tablet-width) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .fullscreen-popup-outer {
    max-width: none;
  }
}
</style>
