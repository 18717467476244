<template>
  <main class="in-container-editor">
    <div>
      <b-alert fade v-model="dismissSuccessMessageCountDown" class="position-fixed fixed-top text-center"
        style="z-index: 999999; top: 75px; margin: 0 auto;  max-width:550px;" variant="success" dismissible>
        {{ successMessage }}
      </b-alert>
      <b-alert fade v-model="dismissErrorMessageCountDown" class="position-fixed fixed-top text-center"
        style="z-index: 999999; top: 75px; margin: 0 auto;  max-width:550px;" variant="danger" dismissible>
        {{ errorMessage }}
      </b-alert>

      <div id="editor-wrapper">
        <div class="editor-header">
          <div class="editor-currently-editing-header">
            <span>Currently editing</span>
            <a target="_blank" ref="currentModelLink"></a>
          </div>
          <div class="editor-header-right-container">
            <b-link :to="{ name: 'HowTo' }" class="" target="_blank">
              <b>Help Center</b>
            </b-link>
          </div>
        </div>

        <div ref="editorContainer" id="editor-container">
          <OpenSceneFileWindow ref="openFileWindow"></OpenSceneFileWindow>

          <div id="status_top" @click="onClickStatus()">
            <div class="status_middle">
              <div id="status_vertical">
                <div id="loader_status_text"></div>
                <div v-show="showStatusLoaderProgress">
                  <div id="loader-status-spinner-container" v-if="!statusProgressIsAvailable">
                    <b-spinner variant="dark"></b-spinner>
                  </div>
                  <div v-if="statusProgressIsAvailable" id="loader_stripe_container">
                    <div id="loader_stripe"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="editor-web-container">
            <!-- Fake color picker with a single menu -->
            <v-color-picker v-show="showColorPicker" ref="colorPickerElement" v-model="colorPicked" dot-size="29"
              mode="hexa" id="editor-color-picker"></v-color-picker>

            <div class="archengine_border" ref="engineFrame">
              <div class="editor-left-menu-container">
                <SideMenuItem :togglable=false :keepToggled=false id="editor-undo" ref="undo" :index=0
                  :enabledDefault=false iconName="editor-undo.svg"></SideMenuItem>
                <SideMenuItem :togglable=false :keepToggled=false id="editor-redo" ref="redo" :index=0
                  :enabledDefault=false iconName="editor-redo.svg"></SideMenuItem>

                <div class="side-menu-separator"></div>

                <SideMenuItem :togglable=true id="editor-show-move" ref="showMove" :index=0 :enabledDefault=false
                  iconName="editor-move.svg"></SideMenuItem>
                <SideMenuItem :togglable=true id="editor-show-rotate" ref="showRotate" :index=0 :enabledDefault=false
                  iconName="editor-rotate.svg"></SideMenuItem>
                <SideMenuItem :togglable=true id="editor-show-scale" ref="showScale" :index=0 :enabledDefault=false
                  iconName="editor-scale.svg"></SideMenuItem>

                <div class="side-menu-separator"></div>

                <SideMenuItem :togglable=true id="editor-show-grid" ref="showGrid" :index=1 :enabledDefault=true
                  iconName="editor-grid.svg"></SideMenuItem>
              </div>

              <div class="top-hints-container">

                <transition name="fast-transition">
                  <div v-if="showUnsavedChangesLabel" id="scene-changed-warning-label">
                    There are unsaved changes. Press <strong>Submit</strong> or they will be lost.
                    <img id="scene-changed-warning-icon" src="./../assets/warning.svg" />
                  </div>
                </transition>

                <transition name="fast-transition">
                  <div ref="topHint" v-if="showTopHint" id="top-hint">
                    <div v-html="topHintMessage"></div>
                    <img id="top-hint-icon" src="./../assets/bulb.svg" />
                  </div>
                </transition>

              </div>

              <div @click="dumpScene()" id="engine_version"></div>

              <div id="top-menu">
                <button @click="openFile()" id="open-file-btn" class="top-menu-item">Open file</button>
                <button @click="onDeleteModel()" id="delete-model-btn" class="top-menu-item">Delete scene</button>
              </div>

              <DropZone ref="dropZone" @files-dropped="onAddFiles">
                <div id="canvas-wrapper">
                  <canvas ref="canvas" class="scene-canvas" id="canvas" oncontextmenu="event.preventDefault()"
                    tabindex=-1></canvas>
                </div>
              </DropZone>
            </div>

            <SideMenu ref="sideMenu" id="editor-side-menu"></SideMenu>

            <!--div id="left-bottom-controls-container">
              <button ref="shareButton" @click="onShare()" class="icon-label-button" id="share-button">
                <div v-show="!enableShareButton" class="icon-label-button-disabled-overlay"></div>
                <span>Share</span>
                <img src="./../assets/share.svg" />
              </button>

              <button ref="embedButton" @click="onEmbed()" class="icon-label-button" id="embed-button">
                <div v-show="!enableEmbedButton" class="icon-label-button-disabled-overlay"></div>
                <span>Embed</span>
                <img src="./../assets/source_code.svg" />
              </button>

              <button v-show="true" ref="webConfiguratorButton" @click="onWebConfigurator()" class="icon-label-button"
                id="web-configurator-button">
                <div v-show="!enableConfiguratorPageButton" class="icon-label-button-disabled-overlay"></div>
                <span>Configurator (Beta)</span>
                <img src="/img/scene_menu_configurator.svg" />
              </button>
            </div-->

          </div>

          <div id="editor-side-panel">
            <div id="editor-side-panel-content">
              <ScenePublicInfo ref="info"></ScenePublicInfo>
              <SceneProperties ref="props" hidden></SceneProperties>
              <SceneAnnotations ref="annotations" hidden></SceneAnnotations>
              <SceneAnimations ref="animations" hidden></SceneAnimations>
              <SceneMaterials ref="materials" hidden></SceneMaterials>
              <SceneTextures ref="textures" hidden></SceneTextures>
              <SceneObjects ref="objects" hidden></SceneObjects>
              <SceneConfigurator ref="configurator" hidden></SceneConfigurator>
              <SceneMap ref="map" hidden></SceneMap>
            </div>

            <div id="editor-side-panel-top-header-container">
              <div id="editor-side-panel-top-header">Header</div>
            </div>

            <div id="editor-side-panel-bottom-container">
              <button :disabled="isSubmittingScene" @click="onSubmitScene()" class="default-nice-button"
                id="submit-scene-btn">
                <span class="text-white" v-if="!isSubmittingScene">Submit</span>
                <b-spinner v-if="isSubmittingScene" variant="light"></b-spinner>
              </button>
            </div>

          </div>

        </div>

        <ScenePreviewBottomMenu ref="sceneBottomMenu"></ScenePreviewBottomMenu>
      </div>

      <input type="file" ref="fakeFileInput" style="visibility:hidden" />

      <b-tooltip triggers='hover' target="embed-button" placement="topright" customClass="button-tooltip-offset">
        Embed model into a website
        <div v-show="!enableEmbedButton" class="editor-text-tooltip-warning">
          <br>
          Model isn't part of a subscription bundle that allows embedding
        </div>
      </b-tooltip>

      <b-tooltip triggers='hover' target="editor-undo" placement="right">
        Undo
      </b-tooltip>

      <b-tooltip triggers='hover' target="editor-redo" placement="right">
        Redo
      </b-tooltip>

      <b-tooltip triggers='hover' target="editor-show-move" placement="right">
        Show/hide move tool
      </b-tooltip>

      <b-tooltip triggers='hover' target="editor-show-rotate" placement="right">
        Show/hide rotate tool
      </b-tooltip>

      <b-tooltip triggers='hover' target="editor-show-scale" placement="right">
        Show/hide scale tool
      </b-tooltip>

      <b-tooltip triggers='hover' target="editor-show-grid" placement="right">
        Show/Hide grid
      </b-tooltip>
    </div>
  </main>
</template>

<script>
import DropZone from './DropZone.vue'
import EditorColorPicker from './Editor/EditorColorPicker.vue'
import EditorPropertyBool from './Editor/EditorPropertyBool.vue'
import EditorPropertyButton from './Editor/EditorPropertyButton.vue'
import EditorPropertyConfiguratorOption from './Editor/EditorPropertyConfiguratorOption.vue'
import EditorPropertyMultiple from './Editor/EditorPropertyMultiple.vue'
import EditorPropertyObjects from './Editor/EditorPropertyObjects.vue'
import EditorPropertyPicker from './Editor/EditorPropertyPicker.vue'
import EditorPropertySlider from './Editor/EditorPropertySlider.vue'
import EditorPropertyString from './Editor/EditorPropertyString.vue'
import EditorPropertyTypeBased from './Editor/EditorPropertyTypeBased.vue'
import MaterialSelector from './Editor/MaterialSelector.vue'
import NamedMaterialSelector from './Editor/NamedMaterialSelector.vue'
import OpenSceneFileWindow from './Editor/OpenSceneFileWindow.vue'
import SceneAnimations from './Editor/SceneAnimations.vue'
import SceneAnnotations from './Editor/SceneAnnotations.vue'
import SceneConfigurator from './Editor/SceneConfigurator.vue'
import SceneMap from './Editor/SceneMap.vue'
import SceneMaterials from './Editor/SceneMaterials.vue'
import SceneObjects from './Editor/SceneObjects.vue'
import SceneProperties from './Editor/SceneProperties.vue'
import ScenePublicInfo from './Editor/ScenePublicInfo.vue'
import SceneTextures from './Editor/SceneTextures.vue'
import SideMenu from './Editor/SideMenu.vue'
import SideMenuItem from './Editor/SideMenuItem.vue'
import TextureSelector from './Editor/TextureSelector.vue'
import ScenePreviewBottomMenu from './ScenePreviewBottomMenu.vue'

import Vue from 'vue'
import { MODEL_FLAG } from "../const"

export default {
  components: {
    ScenePublicInfo,
    SceneProperties,
    SceneAnnotations,
    SceneAnimations,
    SceneMaterials,
    SceneConfigurator,
    SceneTextures,
    SceneObjects,
    OpenSceneFileWindow,
    SideMenu,
    SideMenuItem,
    DropZone,
    SceneMap,
    ScenePreviewBottomMenu
  },
  data: function () {
    return {
      enableShareButton: true,
      enableEmbedButton: true,
      enableConfiguratorPageButton: false,
      dismissSuccessMessageCountDown: 0,
      dismissErrorMessageCountDown: 0,
      successMessage: null,
      errorMessage: null,
      modelId: 0,
      isSubmittingScene: false,
      zipFileDestination: "/default.zip",
      module: null,
      fs: null,
      statusProgressIsAvailable: false,
      showStatusLoaderProgress: true,
      sceneHash: "",
      qrPath: "/qr.png",
      canCloseStatus: false,
      showUnsavedChangesLabel: false,
      showPanelIndex: 0,
      isSceneChanged: false,
      isInfoChanged: false,
      jsonProps: null,
      sceneData: null,
      uploadPreviewCallback: null,
      showTopHint: false,
      topHintMessage: "",
      activeColorPicker: null, // currently active picker
      colorPicked: "", // hex
      setStatusLast: 0,
      enableColorAlpha: false,
      // Open files
      openFileNameArray: [],
      openFileArray: [],
      processFileDirectory: "/files_to_process",
      addFilesToScene: true,
      canvasIsFocused: false,
      showWebConfiguratorButton: false,
      showColorPicker: false,
      notifyScenePropChange: true,
      modulePostRun: [
        function () {

        }
      ]
    }
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["3d-editor"];
  },
  mounted() {
    let _this = this;

    Vue.prototype.$editor = this

    this.$root.$on('updateUserInfo', (info) => this.updateUserInfo(info));
    if (this.$root.userInfo) {
      this.$refs.sideMenu.setUserInfo(this.$root.userInfo)
    }
    // prevent editor from scrolling (it's really annoying when you scroll here and there)
    this.disableScrollOutsideElement(this.$refs.editorContainer, "editor")

    this.sceneHash = this.getSceneHash();
    this.getUserInfoAsync()

    // Add engine code
    const archwebscript = document.createElement("script");
    let scriptURL = this.getWebsiteURL(true) + "/archweb_editor.js?version=" + process.env.VUE_APP_ARCHWEB_VERSION
    archwebscript.setAttribute("src", scriptURL);
    document.body.appendChild(archwebscript);

    this.$refs.sceneBottomMenu.onShare = this.onShare
    this.$refs.sceneBottomMenu.enableEmbed = this
    this.$refs.sceneBottomMenu.onShowMapClick = this.onShowMapClick
    this.$refs.sceneBottomMenu.onSharePNGClick = this.onSharePNGClick
    this.$refs.sceneBottomMenu.onShareJPGClick = this.onShareJPGClick
    
    //this.$refs.sceneBottomMenu.showSpaceBetweenGroups = true

    archwebscript.onload = function () {
      var createModule = eval('CreateModule');
      createModule({ locateFile: _this.locateFile, postRun: _this.modulePostRun, setStatus: _this.setStatus }).then(function (module) {
        _this.module = module;
        _this.fs = module.FS;

        Vue.prototype.$archengine = module

        _this.module.ccall('callFromJS', null, ['string', 'string'], ["setToken", _this.$store.state.token]);
        if (_this.$store.state.user) {
          _this.setUserInfo(_this.$store.state.user)
        }

        _this.module.ccall('callFromJS', null, ['string', 'string', 'string'], ["setEngineSetting", "show-engine-version", "1"]);
        _this.module.ccall('callFromJS', null, ['string', 'string', 'string'], ["setEngineSetting", "allow-fullscreen", "0"]);
        _this.module.ccall('callFromJS', null, ['string', 'string', 'string'], ["setEngineSetting", "scene", _this.getSceneHash()]);
        _this.module.ccall('callFromJS', null, ['string', 'string', 'string'], ["setEngineSetting", "annotations-toggle", "0"]);

        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
          _this.module.ccall('callFromJS', null, ['string', 'string', 'string'], ["setEngineSetting", "allowCDN", "0"]);
          _this.module.ccall('callFromJS', null, ['string', 'string', 'string'], ["setEngineSetting", "debugInfo", "1"]);
        }


        _this.showPanel(0);

        _this.module.editor = _this;

        _this.statusProgressIsAvailable = true;

        _this.loadSceneInfo();
      });
    }

    this.$refs.showMove.onClick = this.onClickShowMove
    this.$refs.showRotate.onClick = this.onClickShowRotate
    this.$refs.showScale.onClick = this.onClickShowScale
    this.$refs.showGrid.onClick = this.onClickShowGrid

    this.$refs.undo.onClick = this.onClickUndo
    this.$refs.redo.onClick = this.onClickRedo

    this.$refs.showGrid.on = true

    document.addEventListener("keydown", this.onEngineKeyDown);
    document.addEventListener("keyup", this.onEngineKeyUp);

    document.addEventListener('mousedown', this.onMouseDown);
  },
  methods: {
    setUserInfo(user) {
      if (user) {
        this.module.ccall('callFromJS', null, ['string', 'string'], ["setMaxModelSize", user.maxFileSize.toString()]);
      }
    },
    getUserInfoAsync() {
      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/user/security", { headers: headers }).then(function (response) {
        _this.$store.state.user = response.data
        _this.setUserInfo(response.data)
      }).catch(function (error) {

      });
    },
    onMouseDown(e) {
      this.canvasIsFocused = e.target == this.$refs.canvas

      if (!this.hasElementParentWithId(e.target, "editor-color-picker")) {
        this.showColorPicker = false
      }
    },
    enableTransformControls(enable) {
      this.$refs.showMove.enabled = enable;
      this.$refs.showScale.enabled = enable;
      this.$refs.showRotate.enabled = enable;

      if (!enable) {
        this.$refs.showMove.on = false;
        this.$refs.showScale.on = false;
        this.$refs.showRotate.on = false;
      }
    },
    showUndo(show) {
      this.$refs.undo.enabled = show;
    },
    showRedo(show) {
      this.$refs.redo.enabled = show;
    },
    onClickUndo() {
      this.module.ccall('callFromJS', 'string', ['string'], ["undo"]);
    },
    onClickRedo() {
      this.module.ccall('callFromJS', 'string', ['string'], ["redo"]);
    },
    showAlert(msg) {
      let _this = this;
      this.$root.$emit('showDialog', "Warning", msg, null, "Close", function () {
        
      })
    },
    onEngineKeyDown(e) {
      if (this.canvasIsFocused) {
        var response = this.module.ccall('callFromJS', 'string', ['string', 'string'], ["onKeyDown", e.key]);
        response = eval('(' + response + ')')
        if (response["handled"] == true) {
          e.preventDefault();
          e.stopPropagation()
        }
      }
    },
    onEngineKeyUp(e) {
      if (this.canvasIsFocused) {
        var response = this.module.ccall('callFromJS', 'string', ['string', 'string'], ["onKeyUp", e.key]);

        response = eval('(' + response + ')')
        if (response["handled"] == true) {
          e.preventDefault();
          e.stopPropagation()
        }
      }
    },
    onAddFiles(files) {
      this.addFiles(files, true)
    },
    addFiles(files, addToScene) {
      this.openFileArray = files
      this.openFileNameArray = []

      this.setStatusText("Processing files...");
      this.showStatusContainer();
      this.statusProgressIsAvailable = true;

      this.removeFileRecursively(this.processFileDirectory)
      this.onChangeIsMadeForce(true/*scene*/, false/*info*/)

      this.fs.mkdir(this.processFileDirectory)
      this.processFile(0, addToScene);
    },
    doneProcessingFiles(addToScene) {
      this.setStatusText("Opening...");
      this.statusProgressIsAvailable = false;

      this.module.ccall('processFileList', null, ['string', 'string', 'string'], [JSON.stringify(this.openFileNameArray), addToScene ? "1" : "0"]);
    },
    processFile(fileIndex, addToScene) {
      if (fileIndex >= this.openFileArray.length) {
        this.doneProcessingFiles(addToScene);
        return;
      }

      this.setStatusText("Processing file " + (fileIndex + 1) + " of " + this.openFileArray.length);

      let file = this.openFileArray[fileIndex];
      let fileName = file.name;
      let filePath = this.processFileDirectory + "/" + fileName

      let _this = this;

      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function (evt) {
        let data = new Uint8Array(evt.target.result);

        // Store the file
        let stream = _this.fs.open(filePath, 'w+');
        _this.fs.write(stream, data, 0, data.length, 0);
        _this.fs.close(stream);

        _this.openFileNameArray.push(filePath)

        _this.processFile(fileIndex + 1, addToScene);
      }
      reader.onerror = function (evt) {
        _this.processFile(fileIndex + 1, addToScene);
      }
      reader.onprogress = function (data) {
        if (data.lengthComputable) {
          _this.setProgress(data.loaded / data.total);
        }
      }
    },
    updateUserInfo(info) {
      if (this.$refs.sideMenu) {
        this.$refs.sideMenu.setUserInfo(info)
      }
    },
    onClickShowMove(item) {
      this.$refs.showRotate.on = false
      this.$refs.showScale.on = false

      var on = item.on
      this.module.ccall('callFromJS', 'string', ['string', 'string'], ["showMove", on ? "1" : "0"]);
    },
    onClickShowScale(item) {
      this.$refs.showRotate.on = false
      this.$refs.showMove.on = false

      var on = item.on
      this.module.ccall('callFromJS', 'string', ['string', 'string'], ["showScale", on ? "1" : "0"]);
    },
    onClickShowRotate(item) {
      this.$refs.showMove.on = false
      this.$refs.showScale.on = false

      var on = item.on
      this.module.ccall('callFromJS', 'string', ['string', 'string'], ["showRotate", on ? "1" : "0"]);
    },
    onClickShowGrid(item) {
      var on = item.on
      this.module.ccall('callFromJS', 'string', ['string', 'string'], ["showGrid", on ? "1" : "0"]);
    },
    getSceneDump() {
      var response = this.module.ccall('callFromJS', 'string', ['string'], ["getSceneDump"]);
      response = JSON.parse(response)
      return response
    },
    dumpScene() {
      let dump = this.getSceneDump()
      var sceneDumpString = JSON.stringify(dump, null, 4)
      console.log("SCENE: " + sceneDumpString)

      this.copyTextToClipboard(sceneDumpString);
    },
    getTextureList() {
      return this.$refs.textures.$refs.outliner.textureList
    },
    getMaterialList() {
      return this.$refs.materials.$refs.outliner.materialList
    },
    getMaterialById(materialId) {
      var materialList = this.getMaterialList()
      for (var materialIndex in materialList) {
        var material = materialList[materialIndex]
        if (material.id == materialId) {
          return material;
        }
      }
      return null
    },
    getTextureById(textureId) {
      var textureList = this.getTextureList()
      for (var textureIndex in textureList) {
        var texture = textureList[textureIndex]
        if (texture.id == textureId) {
          return texture;
        }
      }
      return null
    },
    onColorPick(picker) {
      let _this = this;

      this.activeColorPicker = picker;
      this.showColorPicker = true

      var colorPicker = this.$refs.colorPickerElement;
      var color = picker.color;
      this.$nextTick(() => {
        if (picker.enableAlpha) {
          var hex = color.substring(0, 7)
          var alphaHex = color.substring(7, 9)
          var alphaInt = parseInt(alphaHex, 16);
          var alphaFloat = alphaInt / 255;
          alphaFloat = Number(alphaFloat.toFixed(2))
          _this.colorPicked = color
          colorPicker.alpha = alphaFloat
        } else {
          _this.colorPicked = color
        }
      });

      this.enableColorAlpha = picker.enableAlpha ? true : false

    },
    loadSceneInfo() {
      let sceneHash = this.getSceneHash();
      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/models/hash", { params: { "hash": sceneHash }, headers: headers }).then(function (response) {
        let scene = response.data
        _this.sceneData = scene;
        _this.$refs.info.setSceneInfo(scene)
        _this.$refs.map.setSceneInfo(scene)
        _this.enableShareButton = scene.flags.includes(MODEL_FLAG.IS_AVAILABLE_BY_QR_AND_LINK)
        _this.enableEmbedButton = scene.enableEmbedding

        var locale = _this.getModelLocale(response.data)
        _this.$refs.currentModelLink.innerHTML = locale ? locale.name : ""
        _this.$refs.currentModelLink.href = process.env.VUE_APP_WEBSITE_URL + "/scene/" + response.data.hash
        _this.$refs.sideMenu.onSceneInfoReceived(_this.sceneData)

        if (response.data.arModels == null || response.data.arModels.length == 0) {
          _this.$nextTick(() => {
            _this.openFile()
          })
        }
        _this.$refs.sceneBottomMenu.configureModel(scene)
        _this.$refs.sceneBottomMenu.enableEmbed = scene.enableEmbedding
        _this.$refs.sceneBottomMenu.enableConfigurator = scene.enableConfigurator

        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
          _this.$refs.sceneBottomMenu.enableSharePNG = true
        }
      }).catch(function (error) {
        console.log("error", error);
      });
    },
    onChangedMaterials(materials) {
      if (this.$refs.objects != null) {
        this.$refs.objects.materials = materials
      }
    },
    onClickMenuItem(index) {
      this.showPanel(index);
    },
    onClose() {
      this.enableLeavePageWarning(false)
    },
    enableLeavePageWarning(enable) {
      if (enable) {
        window.onbeforeunload = function warnUsers() {
          return "There are unsaved changes. If you close this window, they will be lost!"
        }
      } else {
        window.onbeforeunload = null
      }
    },
    showUnsavedChangesWarning(show) {
      this.enableLeavePageWarning(show)
      this.showUnsavedChangesLabel = show;

      if (!show) {
        this.isSceneChanged = false;
        this.isInfoChanged = false;
      }
    },
    setCanCloseStatus(value) {
      this.canCloseStatus = value;
    },
    onClickStatus() {
      if (!this.canCloseStatus) return;

      this.hideStatusContainer();
      this.canCloseStatus = false;
    },
    setStatus(text) {
      if (text.length == 0) return;

      if (!this.setStatusLast) this.setStatusLast = { time: Date.now(), text: '' };
      if (text === this.setStatusLast.text) return;
      var m = text.match(/([^(]+)\((\d+(\.\d+)?)\/(\d+)\)/);
      var now = Date.now();
      if (m && now - this.setStatusLast.time < 30) return; // if this is a progress update, skip it if too soon
      this.setStatusLast.time = now;
      this.setStatusLast.text = text;
      if (m) {
        text = m[1];
        var value = parseInt(m[2]);
        var max = parseInt(m[4]);
        var percent = value / max;
        this.setProgressIsAvailable(true);
        this.setProgress(percent);
      }
      this.setStatusText(text);
    },
    locateFile(path, prefix) {
      const result = this.getWebsiteURL(true) + "/" + path + "?version=" + process.env.VUE_APP_ARCHWEB_VERSION;
      return result;
    },
    getSceneHash() {
      var path = this.$router.currentRoute.path;
      const sceneHash = path.substring(path.lastIndexOf('/') + 1)
      return sceneHash;
    },
    onShare() {
      if (!this.enableShareButton) return

      this.$root.$emit('showShareWindow', this.sceneHash)
    },
    makeSnapshot(uploadPreviewCallback) {
      let snapshotFolderName = "/snapshot"
      this.module.ccall('callFromJS', null, ['string', 'string'], ["makeSnapshot", snapshotFolderName]);
      this.uploadPreviewCallback = uploadPreviewCallback;
    },
    doneUploadingPreviews(success) {
      if (success) {
        this.$refs.info.downloadScenePreview();
      }

      this.$refs.isMakingSnapshot = false;

      if (this.uploadPreviewCallback) {
        this.uploadPreviewCallback();
        this.uploadPreviewCallback = null;
      } else {
        if (success) {
          this.dismissSuccessMessageCountDown = 5;
          this.successMessage = "Images uploaded!" // non-localized
        }
        this.hideStatusContainer();
      }
    },
    uploadScenePreviewImage(index) {
      if (index > 2) {
        this.doneUploadingPreviews(true);
        return;
      }

      this.setStatusText("Submitting images...");
      this.statusProgressIsAvailable = true;

      let imageNames = ["photo.jpeg", "photo_medium.jpeg", "photo_small.jpeg"]
      let imagePath = "/snapshot/" + imageNames[index]

      // Read the serialized file
      var data = this.fs.readFile(imagePath, { encoding: 'binary' });

      // Send this file
      {
        var formData = new FormData();
        formData.append('modelId', this.modelId);
        formData.append('file', new Blob([data]), imageNames[index]);

        var _this = this;
        this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/files/image/security", formData, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          transformRequest: formData => formData,
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              _this.setProgress(progressEvent.loaded / progressEvent.total);
            }
          }
        }).then(function () {
          _this.uploadScenePreviewImage(index + 1)
        }).catch(function (error) {
          _this.dismissErrorMessageCountDown = 5;
          _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Failed to upload image";
          _this.doneUploadingPreviews(false)
        });
      }
    },
    showMessage(text, isError) {
      if (isError) {
        this.dismissErrorMessageCountDown = 5;
        this.errorMessage = text;
      } else {
        this.dismissSuccessMessageCountDown = 5;
        this.successMessage = text
      }
    },
    onDoneMakingSnapshot() {
      this.showStatusContainer();

      var index = 0;
      this.uploadScenePreviewImage(index)
      this.$refs.info.onDoneMakingSnapshot();
    },
    updateSceneProps() {
      this.notifyScenePropChange = false;

      var response = this.module.ccall('callFromJS', 'string', ['string'], ["getSceneDump"]);
      response = JSON.parse(response)

      let jsonProps = response.scene;

      this.jsonProps = jsonProps;
      if (this.jsonProps instanceof Object) {
        if (this.$refs.props != null) {
          this.$refs.props.setProps(this.jsonProps);
        }
        if (this.$refs.annotations != null) {
          this.$refs.annotations.configure(this.jsonProps.annotations)
        }
        if (this.$refs.animations != null) {
          this.$refs.animations.configure(this.jsonProps.animations)
        }
        if (this.$refs.materials != null) {
          this.$refs.materials.configure(this.jsonProps.materials)
        }
        // conf
        if (this.$refs.configurator != null) {
          this.$refs.configurator.configure(this.jsonProps.configurator)
        }
        this.showWebConfiguratorButton = this.jsonProps.configurator.entries.length > 0
        if (this.$refs.textures != null) {
          this.$refs.textures.configure(this.jsonProps.textures)
        }
        if (this.$refs.objects != null) {
          this.$refs.objects.configure(this.jsonProps.objects, this.jsonProps.materials)
        }
      }
      this.notifyScenePropChange = true;
    },
    onOpenedScene(props) {
      let jsonProps = JSON.parse(props)
      //console.log("PROPS: " + JSON.stringify(jsonProps, null, 4))
      this.updateSceneProps()
      this.$refs.sideMenu.onSceneOpened(jsonProps)
    },
    setSidePanelHeaderTitle(title) {
      var headerElement = document.getElementById('editor-side-panel-top-header');
      headerElement.innerHTML = title
    },
    getPanels() {
      return [
        this.$refs.info,
        this.$refs.props,
        this.$refs.annotations,
        this.$refs.objects,
        this.$refs.materials,
        this.$refs.textures,
        this.$refs.animations,
        this.$refs.configurator,
        this.$refs.map
      ]
    },
    getPanelTitles() {
      return [
        "Public information",
        "Scene properties",
        "Annotations",
        "Scene hierarchy",
        "Materials",
        "Textures",
        "Animations",
        "Configurator",
        "Map"
      ]
    },
    showPanel(index) {
      var panels = this.getPanels();
      for (var i = 0; i < panels.length; ++i) {
        var panel = panels[i];
        if (panel == null) continue;

        if (i == index) {
          panel.$el.removeAttribute("hidden");
        } else {
          panel.$el.setAttribute("hidden", "")
        }
      }

      this.showPanelIndex = index;

      var titles = this.getPanelTitles()
      this.setSidePanelHeaderTitle(titles[index])

      this.module.ccall('callFromJS', null, ['string', 'string'], ["enableAnnotationEditMode", index == 2 ? "1" : "0"]);

      if (index == 2 /*annotations*/) {
        this.showTopHint = true;
        this.topHintMessage = "Double-click to add an annotation"
      } else {
        this.showTopHint = false;
      }

      if (this.showPanelIndex != 3 && index == 3/*objects*/) {
        this.$refs.objects.configure(this.jsonProps.objects, this.jsonProps.materials)
      }
    },
    saveScene() {
      this.setStatusText("Saving scene...");
      this.statusProgressIsAvailable = false;

      // TODO: process errors
      //  Serialize/Save scene via engine side to the file
      this.module.ccall('callFromJS', null, ['string', 'string'], ["saveScene", this.zipFileDestination]);
    },
    submitSceneFile(callback) {
      var fileStat = this.fs.analyzePath(this.zipFileDestination, true)
      if (!fileStat.exists) {
        this.dismissErrorMessageCountDown = 3;
        this.errorMessage = "Scene is empty";
        callback(false);
        return;
      }

      // Read the serialized file
      var data = this.fs.readFile(this.zipFileDestination, { encoding: 'binary' });

      // Send this file
      {
        let trianglesCount = this.module.ccall('getTrianglesCount', 'number', [], []);

        var formData = new FormData();
        formData.append('hash', this.sceneHash);
        formData.append('triangles', trianglesCount)
        formData.append('file', new Blob([data]), "default.zip");
        formData.append('platformId', 1 /*ios - legacy param*/);

        this.statusProgressIsAvailable = true;

        var _this = this;
        this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/files/uploadScene", formData, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          transformRequest: formData => formData,
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              _this.setProgress(progressEvent.loaded / progressEvent.total);
            }
          }
        }).then(function () {
          _this.dismissSuccessMessageCountDown = 5;
          _this.successMessage = "Scene uploaded"

          if (callback) {
            callback(true);
          }
        }).catch(function (error) {
          _this.dismissErrorMessageCountDown = 5;
          _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
          if (callback) {
            callback(false);
          }
        });
      }
    },
    showStatusContainer() {
      var element = document.getElementById('status_top');
      if (element) {
        element.style["display"] = "table";
      }
    },
    hideStatusContainer() {
      var element = document.getElementById('status_top');
      if (element) {
        element.style["display"] = "none";
      }
      this.canCloseStatus = false;
    },
    doesSceneHaveConfigurator(sceneDump) {
      // Check if scene has configurator and at least one entry with at least 2 options
      if (Object.hasOwn(sceneDump, "configurator") &&
        sceneDump.configurator &&
        Object.hasOwn(sceneDump.configurator, "entries") &&
        sceneDump.configurator.entries &&
        sceneDump.configurator.entries.length > 0) {
        for (let entryIndex in sceneDump.configurator.entries) {
          let entry = sceneDump.configurator.entries[entryIndex]
          if (Object.hasOwn(entry, "options") && entry.options && entry.options.length >= 2) {
            return true
          }
        }
      }

      return false;
    },
    submitInfo(callback) {
      this.statusProgressIsAvailable = false;
      this.setStatusText("Updating public info...");

      // send info
      {
        let sceneDump = this.getSceneDump().scene

        var locales = [{
          "id": 0,
          "name": this.$refs.info.name,
          "title": "",
          "subtitle": "",
          "htmlDescription": "",
          "description": this.$refs.info.description,
          "cultureId": 1/*en*/
        }];
        var flagIds = [MODEL_FLAG.IS_FREE, MODEL_FLAG.IS_AVAILABLE_IOS, MODEL_FLAG.IS_AVAILABLE_ANDROID];

        if (this.$refs.info.realScale) flagIds.push(MODEL_FLAG.HAS_ONE_TO_ONE_SCALE)
        if (this.$refs.info.availableByLink) flagIds.push(MODEL_FLAG.IS_AVAILABLE_BY_QR_AND_LINK)
        if (this.$refs.info.sceneIsPrivate) flagIds.push(MODEL_FLAG.IS_PRIVATE)

        if (Object.hasOwn(sceneDump, "annotations") && sceneDump.annotations && sceneDump.annotations.length > 0) {
          flagIds.push(MODEL_FLAG.HAS_ANNOTATIONS)
        }
        if (this.doesSceneHaveConfigurator(sceneDump)) {
          flagIds.push(MODEL_FLAG.HAS_CONFIGURATOR)
        }

        var updateInfo = {
          "keywords": this.$refs.info.keywords,
          id: this.modelId,
          locales: locales,
          flagIds: flagIds
        }

        if (this.$refs.map.latitude != undefined) updateInfo.latitude = this.$refs.map.latitude
        if (this.$refs.map.longitude != undefined) updateInfo.longitude = this.$refs.map.longitude

        var _this = this;
        this.axios.put(process.env.VUE_APP_API_BASEURL + "/api/v1/models/security", updateInfo, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          }
        }).then(function (response) {
          _this.dismissSuccessMessageCountDown = 5;
          _this.successMessage = "Scene uploaded"
          if (callback) {
            callback(true)
          }
          let scene = response.data
          _this.$refs.sceneBottomMenu.configureModel(scene)
        }).catch(function (error) {
          _this.dismissErrorMessageCountDown = 5;
          _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
          if (callback) {
            callback(false)
          }
        });
      }
    },
    setStatusText(text) {
      var element = document.getElementById('loader_status_text');
      if (element) {
        element.innerHTML = text;
      }
    },
    onSubmitScene() {
      if (this.isSceneChanged == false && this.isInfoChanged == false) {
        this.dismissSuccessMessageCountDown = 3;
        this.successMessage = "No changes were made"
        return;
      }
      this.isSubmittingScene = true;
      this.showStatusContainer();

      if (this.isSceneChanged) {
        this.saveScene();
      } else if (this.isInfoChanged) {
        this.submitSceneInfo();
      }
    },
    onDeleteModel() {
      let _this = this;
      this.$root.$emit('showDialog', "Warning", "Do you really want to delete this scene? This action cannot be undone!", "Cancel", "Delete 3D Scene", function () {
        _this.onChangeIsMadeForce(false, false);
        _this.deleteModel();
      })
    },
    deleteModel() {
      this.setProgressIsAvailable(false);
      this.showStatusContainer();
      this.setStatusText("Deleting scene...");

      let sceneHash = this.getSceneHash();
      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.delete(process.env.VUE_APP_API_BASEURL + "/api/v1/models/security", { params: { "modelId": this.modelId }, headers: headers }).then(function (response) {
        _this.$router.push({ name: "MyScenes" })
      }).catch(function (error) {
        console.log("error", error);
      });
    },
    openFile() {
      this.canCloseStatus = false;
      this.$refs.openFileWindow.show = true;
    },
    setProgressIsAvailable(available) {
      this.statusProgressIsAvailable = available;
    },
    submitSceneInfo() {
      var _this = this;

      _this.setStatusText("Submitting scene info...");

      this.submitInfo(function (success) {
        _this.isSubmittingScene = false;
        _this.hideStatusContainer();
        _this.showUnsavedChangesWarning(false)

        // update info
        _this.loadSceneInfo();
      })
    },
    onSavedScene() {
      var _this = this;

      var uploadAllCallback = function () {
        _this.setStatusText("Submitting scene...");
        _this.submitSceneFile(function (success) {
          if (success) {
            _this.submitSceneInfo()
          } else {
            _this.isSubmittingScene = false;
            _this.hideStatusContainer();
          }
        })
      }

      if (this.sceneData && this.sceneData.images && this.sceneData.images.length == 0) {
        this.makeSnapshot(uploadAllCallback)
      } else {
        uploadAllCallback();
      }
    },
    onChangeIsMade(newValue, oldValue, component, isScene, isInfo) {
      if (newValue !== oldValue && component.initIsDone) {
        this.onChangeIsMadeForce(isScene, isInfo)
      }
    },
    onMadeSceneChange() {
      this.onChangeIsMadeForce(true /*scene*/, false /*info*/);
    },
    onCookDone() {
      this.onChangeIsMadeForce(true /*scene*/, false /*info*/);
      this.$refs.openFileWindow.close();
    },
    onChangeIsMadeForce(isScene, isInfo) {
      this.isSceneChanged = this.isSceneChanged || isScene;
      this.isInfoChanged = this.isInfoChanged || isInfo;
      this.showUnsavedChangesWarning(isScene || isInfo)
    },
    setProgress(percent) {
      if (percent > 1) percent = 1;
      if (percent < 0) percent = 0;
      var element = document.getElementById('loader_stripe');
      if (element) {
        element.style.width = (percent * 100) + "%";
        this.statusProgressIsAvailable = true;
      }
    },
    onEmbed() {
      if (!this.enableEmbedButton) return

      this.$root.$emit("showEmbedSceneWindow", this.sceneHash, this.$refs.info.name);
    },
    onWebConfigurator() {
      if (!this.enableConfiguratorPageButton) return

      let link = process.env.VUE_APP_WEBSITE_URL + "/archweb_conf.html?"
      link += "version=" + process.env.VUE_APP_ARCHWEB_VERSION
      link += "&scene=" + this.sceneHash
      link += "&preset=web-conf"
      if (process.env.NODE_ENV === "development") {
        link += "&allowCDN=0"
        link += "&resourcesURL=" + process.env.VUE_APP_STATIC_URL
        link += "&api=" + process.env.VUE_APP_API_BASEURL
        link += "&website-url=" + process.env.VUE_APP_WEBSITE_URL
      }

      window.open(link, "_blank")
    },
    onSelectAnnotation(name) {
      this.module.ccall('callFromJS', null, ['string', 'string'], ["selectAnnotation", name]);

      this.enableTransformControls(name && name.length >= 0)
    },
    onHighlightAnnotation(name) {
      this.module.ccall('callFromJS', null, ['string', 'string'], ["highlightAnnotation", name]);
    },
    onEngineSelectNode(isAnnotation, name, nodeId, meshId) {
      if (isAnnotation) {
        this.$refs.annotations.selectByName(name, true);
        this.$refs.objects.deselect(true/*from engine*/)

        // switch to objects editor
        //if (name.length > 0) {
          //this.$refs.sideMenu.onClickItem(2);
          //this.showPanel(2); // annotations
        //}
      } else {
        this.$refs.annotations.selectByName("", true);
        this.$refs.objects.selectNodeById(false, nodeId, meshId, true/*from engine*/)

        // switch to objects editor. Don't switch if we on annotations because of double-click action
        //if (nodeId >= 0 && this.showPanelIndex != 2/*annotations*/) {
        //  if (this.showPanelIndex == 4/*materials*/) {
        //    var mesh = this.$refs.objects.$refs.outliner.findMesh(meshId)
        //    if (mesh) {
        //      this.$refs.materials.selectById(mesh.materialId);
        //    }
        //  } else {
        //    this.$refs.sideMenu.onClickItem(3);
        //    this.showPanel(3); // objects
        //  }
        //}
      }

      this.enableTransformControls(nodeId >= 0)
    },
    onEngineHighlightNode(isAnnotation, name, nodeId, meshId) {
      if (isAnnotation) {
        this.$refs.annotations.highlightByName(name);
        this.$refs.objects.selectNodeById(true, -1, meshId, true/*from engine*/)
      } else {
        this.$refs.annotations.highlightByName("");
        this.$refs.objects.selectNodeById(true, nodeId, meshId, true/*from engine*/)
      }
    },
    updateObjectList() {
      this.$refs.objects.updateObjectList()
    },
    updateAnnotationList(list) {
      if (this.$refs.annotations != null) {
        this.$refs.annotations.configure(eval(list))
      }
      this.onChangeIsMadeForce(true /*scene*/, false /*info*/);
    },
    createStringPropertyItem(key, titleText, multiline, rowsAmount, isDisabled, value, callback) {
      var ComponentClass = Vue.extend(EditorPropertyString)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.headerText = titleText
      item.propKey = key
      item.value = value
      if (multiline) {
        item.multiline = multiline
      }
      if (rowsAmount) {
        item.rowsAmount = rowsAmount
      }
      if (isDisabled) {
        item.isDisabled = isDisabled
      }

      item.onChange = callback

      return item
    },
    createBoolPropertyItem(key, titleText, value, callback) {
      var ComponentClass = Vue.extend(EditorPropertyBool)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.titleText = titleText
      item.propKey = key
      item.value = value
      item.onChange = callback

      return item
    },
    createLimitedNumberPropertyItem(key, min, max, titleText, value, callback) {
      var ComponentClass = Vue.extend(EditorPropertySlider)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.titleText = titleText
      item.propKey = key
      item.min = min;
      item.max = max;
      item.value = value
      this.$nextTick(() => {
        item.onChange = callback
      })
      return item
    },
    createPickerPropertyItem(key, titleText, options, value, callback) {
      var ComponentClass = Vue.extend(EditorPropertyPicker)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.titleText = titleText
      item.propKey = key
      item.options = options
      item.value = value
      item.onChange = callback

      return item
    },
    createButtonPropertyItem(titleText, callback) {
      var ComponentClass = Vue.extend(EditorPropertyButton)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.titleText = titleText
      item.onClickCallback = callback

      return item
    },
    createTypeBasedPropertyItem(config, object, onChangeCallback, getCurrentType, getPropertyValueCallback, onChangeTypeCallback) {
      var ComponentClass = Vue.extend(EditorPropertyTypeBased)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.onChange = onChangeCallback
      item.getCurrentType = getCurrentType
      item.onChangeType = onChangeTypeCallback
      item.getPropertyValue = getPropertyValueCallback
      item.object = object
      item.configure(config)

      return item
    },
    createColorPropertyItem(key, value, itemConfig, onChangeCallback) {
      var ComponentClass = Vue.extend(EditorColorPicker)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.$el.className = "editor-property-typebased-color-picker"
      item.setColor(value)
      item.enableAlpha = itemConfig.enableAlpha
      item.titleText = itemConfig.title
      this.$nextTick(() => {
        item.onChange = function (newColor) {
          onChangeCallback({ type: "color", value: newColor }, key)
        }
      });

      return item
    },
    createTexturePropertyItem(key, value, itemConfig, onChangeCallback) {
      var ComponentClass = Vue.extend(TextureSelector)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.$el.className += " editor-property-typebased-texture-selector"
      item.onChange = onChangeCallback
      item.key = key

      if (Object.hasOwn(itemConfig, "showEmbedded")) {
        item.showEmbedded = itemConfig.showEmbedded
      }
      item.configure(value)

      return item
    },
    createMaterialPropertyItem(key, value, itemConfig, onChangeCallback) {
      var ComponentClass = Vue.extend(MaterialSelector)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.$el.className += " editor-property-typebased-material-selector"
      item.onChange = onChangeCallback
      item.key = key
      if (Object.hasOwn(itemConfig, "title")) {
        item.headerTitle = itemConfig.title
      }
      if (Object.hasOwn(itemConfig, "multiple")) {
        item.multiple = itemConfig.multiple
      }

      item.configure(value)

      return item
    },
    createNamedMaterialPropertyItem(key, value, itemConfig, onChangeCallback) {
      var ComponentClass = Vue.extend(NamedMaterialSelector)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.onChange = onChangeCallback
      item.key = key
      item.headerTitle = itemConfig.title
      item.configure(value)

      return item
    },
    createMultiplePropertyItem(key, value, itemConfig, onChangeCallback) {
      var ComponentClass = Vue.extend(EditorPropertyMultiple)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.onChange = onChangeCallback
      item.key = key
      item.headerTitle = itemConfig.title
      item.configure(value, itemConfig)

      return item
    },
    createObjectsPropertyItem(key, value, itemConfig, onChangeCallback) {
      var ComponentClass = Vue.extend(EditorPropertyObjects)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.onChange = onChangeCallback
      item.key = key
      item.headerTitle = itemConfig.title
      item.configure(value, itemConfig)

      return item
    },
    createConfiguratorOptionPropertyItem(key, value, itemConfig, onChangeCallback) {
      var ComponentClass = Vue.extend(EditorPropertyConfiguratorOption)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.onChange = onChangeCallback
      item.key = key
      item.headerTitle = itemConfig.title
      item.configure(value, itemConfig)

      return item
    },
    editMaterial(materialId) {
      this.$refs.sideMenu.onClickItem(4);
      this.showPanel(4); // material
      if (this.$refs.materials != null) {
        this.$refs.materials.selectById(materialId);
      }
    },
    addProperties(config, object, propContainer, propertyEditItemMap, changeCallback, getCurrentTypeCallback, getPropertyValueCallback, onChangeTypeCallback) {
      var itemArray = []

      for (var index in config) {
        var itemConfig = config[index]
        var item;
        var value;

        if (itemConfig.key && itemConfig.key.length > 0) {
          if (getPropertyValueCallback) {
            value = getPropertyValueCallback(itemConfig.key, itemConfig.type)
          } else {
            value = object[itemConfig.key]
          }
        }

        if (Object.hasOwn(itemConfig, "multiple") && itemConfig.multiple == true) {
          item = this.createMultiplePropertyItem(itemConfig.key, value, itemConfig, changeCallback)
        } else if (itemConfig.type == "string") {
          item = this.createStringPropertyItem(itemConfig.key, itemConfig.title, itemConfig.multiline || false, itemConfig.rowsAmount || 1, itemConfig.isDisabled || false, value, changeCallback)
        } else if (itemConfig.type == "bool") {
          item = this.createBoolPropertyItem(itemConfig.key, itemConfig.title, value, changeCallback)
        } else if (itemConfig.type == "limitedNumber") {
          item = this.createLimitedNumberPropertyItem(itemConfig.key, itemConfig.min, itemConfig.max, itemConfig.title, value, changeCallback)
        } else if (itemConfig.type == "picker") {
          var options = itemConfig.getOptions()
          item = this.createPickerPropertyItem(itemConfig.key, itemConfig.title, options, value, changeCallback)
        } else if (itemConfig.type == "button") {
          item = this.createButtonPropertyItem(itemConfig.title, itemConfig.onClickCallback)
        } else if (itemConfig.type == "typeBased") {
          item = this.createTypeBasedPropertyItem(itemConfig, object, changeCallback, getCurrentTypeCallback, getPropertyValueCallback, onChangeTypeCallback)
        } else if (itemConfig.type == "color") {
          item = this.createColorPropertyItem(itemConfig.key, value, itemConfig, changeCallback)
        } else if (itemConfig.type == "texture") {
          item = this.createTexturePropertyItem(itemConfig.key, value, itemConfig, changeCallback)
        } else if (itemConfig.type == "material") {
          item = this.createMaterialPropertyItem(itemConfig.key, value, itemConfig, changeCallback)
        } else if (itemConfig.type == "namedMaterial") {
          item = this.createNamedMaterialPropertyItem(itemConfig.key, value, itemConfig, changeCallback)
        } else if (itemConfig.type == "objects") {
          item = this.createObjectsPropertyItem(itemConfig.key, value, itemConfig, changeCallback)
        } else if (itemConfig.type == "configuratorOption") {
          item = this.createConfiguratorOptionPropertyItem(itemConfig.key, value, itemConfig, changeCallback)
        }

        if (item != null) {
          if (propertyEditItemMap && itemConfig.key && itemConfig.key.length > 0) {
            // need for when the value is changed, to call callback
            propertyEditItemMap[itemConfig.key] = item
          }

          if (Object.hasOwn(itemConfig, "tooltip")) {
            item.tooltip = itemConfig.tooltip
          }

          propContainer.appendChild(item.$el)
        }

        itemArray.push(item)
      }

      return itemArray
    },
    onChangeProperty(name, value) {
      if (name == "availableByLink") {
        this.enableShareButton = value
      }
    },
    onSharePNGClick() {
      let snapshotFolderName = "/snapshot"
      this.module.ccall('callFromJS', null, ['string', 'string', 'string'], ["makeSnapshot", snapshotFolderName, "png|async|transparent"]);
  
      var data = this.module.FS.readFile("/snapshot/render1.png", { encoding: 'binary' });

      var file = new Blob([data], { type: 'image/png' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    },
    onShareJPGClick() {
      let snapshotFolderName = "/snapshot"
      this.module.ccall('callFromJS', null, ['string', 'string', 'string'], ["makeSnapshot", snapshotFolderName, "png|async"]);
  
      var data = this.module.FS.readFile("/snapshot/render1.png", { encoding: 'binary' });

      var file = new Blob([data], { type: 'image/png' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    },
    async onShowMapClick() {
      this.showSceneMapPopup(this.sceneData)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.showUnsavedChangesLabel) {
      let _this = this;
      this.$root.$emit('showDialog', "Warning", "If you leave the editor, all the changes will be lost!", "Cancel", "Leave anyway", function () {
        _this.onClose()
        next()
      })
    } else {
      this.onClose()
      next()
    }
  },
  watch: {
    colorPicked(newColor) {
      if (this.activeColorPicker) {
        var hex = newColor;

        if (this.activeColorPicker.enableAlpha && hex.length == 7) {

          hex = newColor.substring(0, 7);

          var colorPicker = this.$refs.colorPickerElement;
          var alpha = colorPicker.alpha
          alpha *= 255
          var alphaHex = alpha.toString(16)
          if (alphaHex.length == 1) {
            alphaHex = '0' + alphaHex
          }
          hex = hex.concat(alphaHex)
        }
        this.activeColorPicker.setColor(newColor)
      }
    }
  }
}
</script>

<style lang="scss">
#editor-side-panel-content {
  padding: 35px 0px 50px 0px;
  height: 100%;
  overflow: hidden;

}

#editor-wrapper {
  margin: 0 auto 00px auto;

  height: calc(100vh - 200px);

  @media (min-width: $desktop-width) {
    height: calc(100vh - 110px);
  }

  position: relative;
}

#editor-container {
  display: flex;
  border: 1px solid #eae7e7;
  border-radius: 3px;
  position: relative;
  height: calc(100% - 50px);
  overflow: hidden;
}

#top-menu {
  position: absolute;
  top: 10px;
  left: 10px;
}

.top-menu-item {
  padding: 5px 8px !important;

  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #111111 !important;

  background-color: #e0e0e0 !important;
  border-radius: 3px !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05),
    0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
  border: none;
}

.top-menu-item:hover,
.top-menu-item:focus {
  background-color: #fdfdfd !important;
  text-decoration: none !important;
}

.top-menu-item:active {
  background-color: #fdfdfd !important;
  text-decoration: none !important;
  border: 0px;
}

#editor-web-container {
  position: relative;
  margin-right: 350px;
  width: 100%;
}

#editor-side-menu {
  width: 50px;
  position: absolute;
  right: 5;
  top: 5;
}

#editor-side-panel {
  width: 350px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #f2f2f2;
  border-radius: 3px;
}

.scene-canvas {
  margin-top: -1px;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-color: #f2f2f2;
  cursor: grab;
  width: 100.2%;
  height: 100.2%;
  text-align: center;
}

#engine_version {
  width: 70px;
  height: 30px;
  position: absolute;
  right: 50px;

  padding-right: 8px;
  text-align: right;
  line-height: 30px;
  color: #ffffff66 !important;
  font-size: 14px;
  font-family: sans-serif;
  ;
  text-shadow: 0 0 4px var(--color-black-a80, rgba(0, 0, 0, 0.2));
}

div.archengine_border {
  border: 0px solid black;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
}

#status_top {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.5);
}

.status_middle {
  display: table-cell;
  vertical-align: middle;
}

#loader_stripe_container {
  width: 200px;
  height: 5px;
  background-color: rgba(200, 200, 200, 255);
  margin: 10px auto;
  border-radius: 2px;
}

#status_vertical {
  text-align: center;
  display: grid;
  width: 20%;
  min-width: 300px;
  margin: auto;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05),
    0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
}

#loader_stripe {
  width: 0%;
  height: 100%;
  background-color: rgba(40, 40, 40, 255);
  border-radius: 2px;
}

#loader_status_text {
  color: rgba(40, 40, 40, 255);
  font-family: "Nunito Sans", Open Sans, sans-serif;
  font-weight: 600;
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
}

#submit-scene-btn {
  width: 95%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  font-weight: 600 !important;
}

#editor-side-panel-bottom-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 50px;
  text-align: center;
  border-top: 1px solid #d9d9d9;
  background-color: #f2f2f2;
}

#editor-side-panel-top-header-container {
  position: absolute;
  width: 100%;
  height: 35px;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
}

#editor-side-panel-top-header {
  color: black;
  padding-left: 10px;
  font-weight: 600;
  line-height: 35px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #d9d9d9;
}

.in-container-editor {
  width: 100%;
  min-width: 320px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;

  background-color: #f8f8f8 !important;

  @media (min-width: $desktop-width) {
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
}

#canvas-wrapper {
  overflow: hidden;
}

#loader-status-spinner-container {
  height: 30px;
  margin-top: 15px;
}

.editor-header-right-container {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(0px, -50%);
}

#share-button {}

.icon-label-button {
  padding: 10px 15px 10px 32px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px !important;
  cursor: pointer;
  border: none;
  position: relative;
}

.icon-label-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.icon-label-button span {
  color: white;
  font-weight: 500;
  line-height: 16px;
  font-size: 16px;
}

.icon-label-button img {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.fast-transition-enter-active,
.fast-transition-leave-active {
  transition: opacity .1s;
}

.fast-transition-enter,
.fast-transition-leave-to {
  opacity: 0;
}

#editor-goto-my-scenes {
  padding: 5px 10px;
  margin-bottom: 10px;
}

#scene-changed-warning-label {
  position: relative;
  color: #161616;
  background-color: #eaac40;
  padding: 5px 10px;
  text-align: center;
  font-weight: 500;
  border-radius: 10px;
  padding-left: 30px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
  margin-bottom: 5px;
}

#top-hint {
  position: relative;
  color: white;
  background-color: black;
  padding: 5px 10px 5px 30px;
  text-align: center;
  font-weight: 500;
  border-radius: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
}

#top-hint-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 8;
  top: 50%;
  transform: translate(0, -50%);
}

#scene-changed-warning-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 8;
  top: 50%;
  transform: translate(0, -50%);
}

#embed-button {
  margin-left: 5px !important;
}

#embed-button img {
  width: 18px;
  height: 18px;
}

#web-configurator-button {
  margin-left: 5px !important;
}

#web-configurator-button img {
  width: 18px;
  height: 18px;
}

.editor-secondary-button {
  box-shadow: none;
  background-color: #59b5bb33;
  color: #59b5bb !important;
  border: 2px solid #59b5bb !important;
  border-radius: 20px !important;
  font-weight: 600;
  text-align: center;
  padding: 3px 0px;
}

.editor-secondary-button:hover {
  border: 2px solid #59b5bbbb !important;
  cursor: pointer;
}

#delete-model-btn {
  margin-left: 5px !important;
  color: #db3030 !important;
}

#dump-scene-btn {
  margin-left: 5px !important;
}

.editor-destructive-btn {
  position: relative;
  padding: 5px 8px !important;

  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #111111 !important;

  background-color: #e0e0e0 !important;
  border-radius: 3px !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09), 0 2px 6px rgba(0, 0, 0, 0.09), 0 0 1px rgba(0, 0, 0, 0.25);
  border: none;

  color: #db3030 !important;
}

.editor-destructive-btn:disabled,
.editor-destructive-btn[disabled] {
  color: #cf8989 !important;
}

.editor-destructive-btn:hover:enabled {
  background-color: #fdfdfd !important;
  text-decoration: none !important;
}

.editor-default-btn {
  position: relative;
  padding: 5px 8px !important;

  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #111111 !important;

  background-color: #e0e0e0 !important;
  border-radius: 3px !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09), 0 2px 6px rgba(0, 0, 0, 0.09), 0 0 1px rgba(0, 0, 0, 0.25);
  border: none;

  color: #3e3e3e !important;
}

.editor-default-btn:disabled,
.editor-default-btn[disabled] {
  color: #aaaaaa !important;
}

.editor-default-btn:hover:enabled {
  background-color: #fdfdfd !important;
  text-decoration: none !important;
}

.editor-property-header {
  color: #616161;
  line-height: 20px;
  font-size: 14;
  font-weight: 500;
  padding: 5 0 1 3;
  text-align: left;
}

.editor-currently-editing-header {
  line-height: 20px;
  font-weight: 600;
  height: 100%;
  padding-left: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
}

#editor-color-picker {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.top-hints-container {
  position: absolute;
  top: 10;
  left: 50%;
  transform: translate(-50%, 0px);
}

.editor-dump-scene-button {
  width: 200px;
  height: 40px;
}

.editor-left-menu-container {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  width: 50px;
  background-color: #00000061;
  border-radius: 9px;
}

.test-cont {
  position: absolute;
  width: 140px;
  height: 60px;
  left: 200px;
  bottom: 50px;

  display: flex;
}

.side-menu-separator {
  height: 1px;
  width: calc(100% - 30px);
  background-color: #0000004a;
  margin: auto;
}

.editor-header {
  width: 100%;
  height: 50px;
  position: relative;
}

.editor-text-tooltip-warning {
  color: #f4ac3e;
}

#left-bottom-controls-container {
  position: absolute;
  left: 10;
  bottom: 10;
}

.button-tooltip-offset {
  margin-left: 150px;
}

.icon-label-button-disabled-overlay {
  background-color: rgba(0, 0, 0, 0.2);

  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: inherit;
}

.icon-label-bordered-button {
  padding: 10px 15px 10px 32px;
  border-radius: 20px !important;
  cursor: pointer;
  position: relative;
  box-shadow: none;
  background-color: rgba(89, 181, 187, 0.2);
  border: 2px solid #59b5bb !important;
}

.icon-label-bordered-button:hover {
  background-color: rgba(89, 181, 187, 0.16);
}

.icon-label-bordered-button span {
  color: white;
  font-weight: 600;
  line-height: 16px;
  font-size: 16px;
  color: #59b5bb !important;
}

.icon-label-bordered-button img {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translate(0, -50%);
}

.icon-label-bordered-button-inactive {
  padding: 10px 15px 10px 32px;
  border-radius: 20px !important;
  cursor: pointer;
  position: relative;
  box-shadow: none;
  background-color: rgba(183, 183, 183, 0.2);
  border: 2px solid #bbbbbb !important;
}

.icon-label-bordered-button-inactive:hover {
  background-color: rgba(184, 184, 184, 0.16);
}

.icon-label-bordered-button-inactive span {
  color: white;
  font-weight: 600;
  line-height: 16px;
  font-size: 16px;
  color: #808080 !important;
}

.icon-label-bordered-button-inactive img {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translate(0, -50%);
}
</style>