<template>
	<div class="animations-editor">
		<div ref="outliner" class="animation-outliner"></div>
		<!--button :disabled="!showAnimationEdit" @click="onRemoveAnimation()" id="animation-remove-btn" class="editor-destructive-btn">Remove animation</button-->

		<div v-html="selectedAnimationName" class="animation-selected-header"></div>

		<div ref="propsContainer" class="animations-editor-props-container" v-show="showAnimationEdit">
			<!--div class="editor-property-header">Animation text</div>
			<b-form-textarea class="editor-multiline-input" id="animation-selected-text" v-model="selectedAnimationText" :rows="10" :max-rows="10" maxlength=512></b-form-textarea-->
		</div>
		<div class="animation-edit-hint" v-show="!showAnimationEdit">Select an animation to edit</div>
    </div>
</template>

<script>

import ObjectItem from './ObjectItem.vue';

import Vue from 'vue';

export default {
	data: function() {
		return {
			animationItemList: [],
			animationList: [],
			selectedAnimationName: "",
			selectedAnimation: null,
			showAnimationEdit: false,
			userEditing: true,
			ignorePropertyChange: false,
			propertyEditConfig: {
				"animation" : [
					{"key": "name", "type": "string", "title": "Name", isDisabled: true },
					{"key": "duration", "type": "string", "title": "Duration (frames)", isDisabled: true }
				]
			},
			propertyEditItemMap: {
				"animation": {}
			}
		}
	},
	components: {
		
	},
	created() {
	},
	mounted() {
		
	},
	methods: {
		configure(animationList) {
			this.animationItemList = [];
			this.animationList = [];
			this.$refs.outliner.innerHTML = "";

			for (var index in animationList) {
				var ComponentClass = Vue.extend(ObjectItem)
				var item = new ComponentClass()
				
				item.onSelect = this.onItemSelect;
				item.onHighlight = this.onItemHighlight
				item.index = index

				item.$mount() // pass nothing
				item.configure(animationList[index], "animation")
				item.expandable = false;

				this.$refs.outliner.appendChild(item.$el)

				this.animationItemList.push(item)
			}
			this.animationList = animationList;

			// reselect if present
			if (this.selectedAnimation) {
				for (let i in this.animationList) {
					let a = this.animationList[i]
					if (a.reference == this.selectedAnimation.reference) {
						const item = this.animationItemList[Number(i)]
						item.select(true)
						break
					}
				}
			}
		},
		onItemSelect(item) {
			this.select(item.index, false)
		},
		onItemHighlight(item) {
			this.highlight(item ? item.index : -1)
		},
		highlightByName(name) {
			for (var i in this.animationItemList) {
				var item = this.animationItemList[i];
				var a = this.animationList[i];

				item.highlight(a.name == name)
			}
		},
		selectByName(name, fromEngine) {
			var index = -1;

			for (var i in this.animationItemList) {
				var item = this.animationItemList[i];
				var a = this.animationList[i];

				if (a.name == name) {
					index = i
					break;
				}
			}

			this.select(index, fromEngine)
		},
		select(index, fromEngine) {
			let _this = this;

			this.userEditing = false;

			let newSelectedName;
			let newSelectedAnimation;

			for (var i in this.animationItemList) {
				var item = this.animationItemList[i]
				var a = this.animationList[i];

				item.select(i == index)

				if (i == index) {
					newSelectedAnimation = a
					newSelectedName = a.name;
				}
			}

			this.selectedAnimation = newSelectedAnimation
			this.selectedAnimationName = newSelectedName;
			this.showPropertiesEditor(newSelectedAnimation)
			this.showAnimationEdit = index >= 0;

			this.$nextTick(() => {
				_this.userEditing = true;
			});
		},
		showPropertiesEditor(object) {
			var type = "animation"

			this.ignorePropertyChange = true;
			this.$refs.propsContainer.innerHTML = ""

			if (object) {
				this.$editor.addProperties(this.propertyEditConfig[type], object, this.$refs.propsContainer, this.propertyEditItemMap[type], this.onChangeProperty, null, this.getPropertyValue, null)
			}

			var _this = this
			this.$nextTick(() => {
				_this.ignorePropertyChange = false
			});
		},
		getPropertyValue(key, type) {
			var selectedObject = this.selectedAnimation

			if (key == "visible") {
				var param = {}
				param["node"] = selectedObject.id
				var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["isObjectVisible", JSON.stringify(param, null, 4)]);
			
				var responseObject = eval( '(' + response + ')');
				return responseObject.visible
			}

			return selectedObject[key]
		},
		onChangeProperty(value, key) {
			var _this = this;
			
			if (this.ignorePropertyChange) return;

			var updateInfo = {};
			updateInfo.reference = this.selectedAnimation.reference;
			updateInfo[key] = value

			this.$editor.module.ccall('callFromJS', null, ['string', 'string'], ["setAnimationInfo", JSON.stringify(updateInfo, null, 2)]);

			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		highlight(index) {
			for (var i in this.animationItemList) {
				var item = this.animationItemList[i]

				item.highlight(i == index)
			}
		},
		onRemoveAnimation() {
			this.$editor.module.ccall('callFromJS', null, ['string', 'string'], ["removeAnimation", this.selectedAnimationName]);
		}
	},
	watch: {

	}
}

</script>

<style lang="scss">
.animations-editor {

}
#animation-remove-btn {
	width: calc(100% - 10px);
	margin: 5px auto !important;
}
.animation-edit-hint {
	text-align: center;
	margin: 15px 10px;
	color: #121212;
}
.animation-selected-header {
	width: 100%;
	height: 30px;
	line-height: 30px;
	color: black;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	background-color: #e1e1e1;
    padding-left: 10px;
    font-weight: 500;
    text-align: left;
}
#animation-selected-text {
	width: 100% !important;
}
.animation-outliner {
	height: 150px;
	overflow: auto;
	overflow-x: hidden;
	background-color: white;
}
.animations-editor-props-container {
	padding: 0px 10px;
}
</style>
