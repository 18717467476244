<template>
  <b-row>
    <b-col>
      <b-alert fade v-model="dismissSuccessMessageCountDown" class="position-fixed fixed-top text-center"
        style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="success" dismissible>
        {{ successMessage }}
      </b-alert>
      <b-alert fade v-model="dismissErrorMessageCountDown" class="position-fixed fixed-top text-center"
        style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="danger" dismissible>
        {{ errorMessage }}
      </b-alert>
      <div class="account-header-container">
        <div class="account-card-header">{{ $t('profile.title') }}</div>
        <b-button class="create-model-button" id="create-model-button-profile" :disabled="sceneCreationInProcess"
          @click="onCreateScene">
          <div id="create-model-button-plus" v-if="!sceneCreationInProcess"></div>
          <span class="text-white" v-if="!sceneCreationInProcess">{{ $t('my-scenes.create-new') }}</span>
          <b-spinner v-if="sceneCreationInProcess" variant="light"></b-spinner>
        </b-button>
      </div>

      <div class="account-content-container">
        <div id="profile-avatar-container">
          <div id="profile-avatar-img-container">
            <img :disabled="uploadAvatarInProcess" id="profile-avatar-img" @mouseover="hoverAvatar()"
              @mouseleave="leaveAvatar()" @click="selectAvatar()"
              onerror="this.onerror=null; this.src='/img/avatar_placeholder.svg'"
              src="../../assets/avatar_placeholder.svg" />
          </div>
          <div v-if="uploadAvatarInProcess" id="profile-avatar-spinner-container">
            <b-spinner id="profile-avatar-spinner" variant="dark"></b-spinner>
          </div>
          <img v-show="!uploadAvatarInProcess" id="profile-avatar-edit-overlay" hidden=true src="../../assets/edit.svg" />
          <div class="profile-avatar-hint"><b>{{ $t('profile.click-on-avatar-hint') }}</b><br>{{
        $t('profile.click-on-avatar-hint-formats') }}</div>
        </div>
        <b-form-group v-bind:label="$t('profile.name')" v-bind:invalid-feedback="$t('profile.field-required')">
          <b-form-input v-model="name" maxlength=64></b-form-input>
        </b-form-group>
        <b-form-group class="username-input-group" v-bind:label="$t('profile.username')"
          v-bind:invalid-feedback="$t('profile.field-required')">
          <b-form-input class="username-input" v-model="username" maxlength=32></b-form-input>
          <div id="username-prefix">@</div>
          <div class="profile-horizontal-grid">
            <div>{{ $t('profile.link-to-profile') }}:</div>
            <a id="username-link"></a>
          </div>
          <!--div>
          <b-button class="text-white" v-on:click="onShareProfile()" variant="primary">{{ $t('profile.share-profile') }}</b-button>
        </div-->
        </b-form-group>

        <b-form-group v-bind:label="$t('profile.about')">
          <b-form-textarea v-model="about" :rows="3" :max-rows="6" maxlength=1024></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <b-button class="float-right text-white" v-on:click="saveProfile()" variant="primary">{{
        $t('profile.save-btn') }}</b-button>
        </b-form-group>

        <b-form-group v-bind:label="$t('profile.email')">
          <b-form-input v-model="email" disabled></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('profile.max-model-size')">
          <b-form-input v-model="maxFileSize" disabled></b-form-input>
        </b-form-group>

        <b-form-group v-bind:label="$t('profile.account-type')">
          <b-form-input v-model="accountType" disabled></b-form-input>
        </b-form-group>

        <hr style="border-style: dashed none;">
        <b-form-group v-bind:label="$t('profile.old-password')" :state="!newPassword ? null : !!currentPassword"
          v-bind:invalid-feedback="$t('profile.field-required')">
          <b-form-input type="password" :state="!newPassword ? null : !!currentPassword"
            v-model="currentPassword"></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('profile.new-password')">
          <b-form-input type="password" v-model="newPassword"
            :state="!newPassword ? null : passwordConfirmation == newPassword"></b-form-input>
        </b-form-group>
        <b-form-group v-bind:label="$t('profile.password-confirmation')"
          :state="!newPassword ? null : passwordConfirmation == newPassword"
          v-bind:invalid-feedback="$t('profile.passwords-do-not-match')">
          <b-form-input type="password" v-model="passwordConfirmation"
            :state="!newPassword ? null : passwordConfirmation == newPassword"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-button class="float-right text-white" v-on:click="updatePassword()" variant="primary">{{
        $t('profile.update-btn') }}</b-button>
        </b-form-group>
        <!--
        <hr style="border-style: dashed none;">
        <br>
        <b-form-group>
          <b-button class="float-right text-white" v-on:click="onDeleteUser()" variant="danger">{{
          $t('profile.delete-btn') }}</b-button>
        </b-form-group>
        -->
      </div>
    </b-col>
  </b-row>
</template>

<script>

export default {
  data: function () {
    return {
      dismissSuccessMessageCountDown: 0,
      dismissErrorMessageCountDown: 0,
      successMessage: null,
      errorMessage: null,
      passwordConfirmation: null,
      newPassword: null,
      currentPassword: null,
      email: null,
      name: null,
      about: null,
      username: null,
      saveButtonClicked: false,
      uploadAvatarInProcess: false,
      sceneCreationInProcess: false,
      maxFileSize: "",
      accountType: ""
    }
  },
  mounted() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["profile"];
    let _this = this;
    let headers = this.getCommonHeaders();
    this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/user/security", { headers: headers }).then(function (response) {
      _this.email = response.data.email;
      _this.name = response.data.name;
      _this.about = response.data.about;
      _this.username = response.data.username;
      _this.updateUsernameLink(response.data.username);
      _this.userId = response.data.id;
      _this.updateAvatar(response.data);
      _this.maxFileSize = _this.humanFileSize(response.data.maxFileSize, true)
      _this.accountType = _this.getAccountTypeString(response.data.accountType)
      if (response.data.id == 1) {
        _this.$store.state.isAdmin = true
      }
    }).catch(function (error) {
      _this.dismissErrorMessageCountDown = 5;
      _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
    });
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["profile"];
  },
  methods: {
    getAccountTypeString(type) {
      if (type == 0) {
        return "Standard"
      } else if (type == 1) {
        return "Business"
      } else if (type == 2) {
        return "Culture"
      }
    },
    onShareProfile() {

    },
    updateAvatar(user) {
      const rand = this.randomString()
      var avatarElement = document.getElementById('profile-avatar-img');
      if (user != null) {
        this.setupAvatarImage(avatarElement, user);
      } else {
        avatarElement.src = process.env.VUE_APP_API_BASEURL + "/api/v1/files/downloadAvatar?userID=" + this.userId + "&rand=" + rand
      }
    },
    onCreateScene() {
      this.sceneCreationInProcess = true
      let _this = this;
      let headers = this.getCommonHeaders();

      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/models/security", {}, { headers: headers }).then(function (response) {
        _this.sceneCreationInProcess = false
        window.location.href = _this.getURLForSceneEdit(response.data.hash)
      }).catch(function (error) {
        _this.sceneCreationInProcess = false;
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        console.log("error", error);
      });
    },
    hoverAvatar() {
      var avatarEditOverlayElement = document.getElementById('profile-avatar-edit-overlay');
      avatarEditOverlayElement.hidden = false;
    },
    leaveAvatar() {
      var avatarEditOverlayElement = document.getElementById('profile-avatar-edit-overlay');
      avatarEditOverlayElement.hidden = true;
    },
    selectAvatar() {
      var avatarElement = document.getElementById('profile-avatar-img');

      var input = document.createElement('input');
      input.type = 'file';

      var _this = this;
      input.onchange = e => {
        _this.uploadAvatarInProcess = true
        avatarElement.hidden = true

        var file = e.target.files[0];

        var formData = new FormData();
        formData.append('file', file);

        this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/files/uploadAvatar", formData, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token,
            "Content-Type": "multipart/form-data",
          }, transformRequest: formData => formData
        }).then(function () {
          avatarElement.hidden = false
          _this.uploadAvatarInProcess = false;
          _this.dismissSuccessMessageCountDown = 5;
          _this.successMessage = "Avatar updated"
          _this.updateAvatar(null);
        }).catch(function (error) {
          avatarElement.hidden = false
          _this.uploadAvatarInProcess = false;
          _this.dismissErrorMessageCountDown = 5;
          _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        });
      }

      input.click();
    },
    updateUsernameLink(username) {
      var usernameLinkElement = document.getElementById('username-link');
      usernameLinkElement.href = this.getURLForProfile(username)
      usernameLinkElement.innerHTML = usernameLinkElement.href;
    },
    required(value) {
      if (!this.saveButtonClicked) return null;
      if (!value || value.length == 0) return false;
      return true;
    },
    onDeleteUser() {
      let _this = this;
      this.$root.$emit('showDialog', this.$t('popup-attention'), this.$t('profile.delete-account-prompt'), this.$t('popup-cancel'), this.$t('profile.delete-btn'), function () {
        _this.deleteUser();
      })
    },
    deleteUser() {

    },
    updatePassword() {
      let _this = this;
      if (!this.newPassword || !this.currentPassword || this.newPassword !== this.passwordConfirmation) return;
      this.axios.put(process.env.VUE_APP_API_BASEURL + "/api/v1/user/security/password", { old: this.currentPassword, new: this.newPassword }, {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        }
      }).then(function () {
        _this.dismissSuccessMessageCountDown = 5;
        _this.successMessage = "Password updated"
      }).catch(function (error) {
        _this.currentPassword = "";
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response.data.cause || error.message;
      });
    },
    saveProfile() {
      if (!this.name) return;
      let _this = this;
      this.saveButtonClicked = true;
      this.axios.put(process.env.VUE_APP_API_BASEURL + "/api/v1/user/security", { name: this.name, about: this.about, username: this.username }, {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        }
      }).then(function () {
        _this.dismissSuccessMessageCountDown = 5;
        _this.successMessage = "Profile updated"
        _this.updateUsernameLink(_this.username)
      }).catch(function (error) {
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response.data.cause;
      });
    }
  }
}
</script>

<style lang="scss">
#profile-avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.02), 0 0 1px rgba(0, 0, 0, 0.02);
}

#profile-avatar-img-container {
  width: 100px;
  height: 100px;
}

#profile-avatar-spinner-container {
  width: 100px;
  height: 100px;
  position: absolute;
}

#profile-avatar-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  margin-left: -0.75rem;
  margin-top: -0.75rem;
}

.profile-avatar-hint {
  width: 300px;
  margin-top: 20px;
  color: #484848;
  margin-left: 20px;

  @media(max-width: $mobile-width-only) {
    font-size: 10px !important;
    margin-left: 10px !important;
    width: 100px !important;
  }
}

#profile-avatar-container {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
}

#profile-avatar-edit-overlay {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 0;
  top: 0;
  padding: 35px;
  pointer-events: none;
}

#username-link {
  padding-left: 8px;
  line-height: 40px;

  @media(max-width: $mobile-width-only) {
    font-size: 14px !important;
  }
}

.username-input-group {
  position: relative;
}

.username-input {
  padding-left: 23px !important;
}

#username-prefix {
  position: absolute;
  width: 23px;
  color: black;
  height: 30px;
  top: 0px;
  line-height: 38px;
  text-align: right;
}

.form-control {
  color: black !important;
}

#create-model-button-profile {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(0px, -50%);
}
.profile-horizontal-grid {
  display: flex;
  padding: 0px 5px;
}
.profile-horizontal-grid div {
  line-height: 40px;
}
</style>
