<template>
  <main>
    <div class="website-page account-container">
      <div class="catalog__bg"></div>

      <b-row class="account__nav">
        <b-col md="3">
          <b-nav pills vertical class="account-menu">
            <b-nav-item :to="{ name: 'Profile' }" exact-active-class="active" exact>{{ $t('account.profile')
              }}</b-nav-item>
            <b-nav-item class="mt-2" :to="{ name: 'Admin' }" exact-active-class="active"
              v-show="this.$store.state.isAdmin">Admin</b-nav-item>
            <b-nav-item class="mt-2" :to="{ name: 'MyScenes' }" exact-active-class="active">{{ $t('account.my-scenes')
              }}</b-nav-item>
            <b-nav-item class="mt-2" :to="{ name: 'MyCollections' }" exact-active-class="active">{{
              $t('account.my-collections') }}</b-nav-item>
            <b-nav-item class="mt-2" :to="{ name: 'Analytics' }" exact-active-class="active">{{
              $t('account.analytics') }}</b-nav-item>
            <b-nav-item class="mt-2" :to="{ name: 'Promo' }" exact-active-class="active">{{
              $t('account.promo') }}</b-nav-item>
            <b-nav-item class="mt-2" :to="{ name: 'Store' }" exact-active-class="active">{{ $t('store.title')
              }}</b-nav-item>
            <b-nav-item class="mt-2" :to="{ name: 'MySubscriptions' }" exact-active-class="active">{{
              mySubscriptionsTitle
            }}</b-nav-item>
            <b-nav-item class="mt-2" :to="{ name: 'Billing' }" exact-active-class="active">{{
              $t('account.billing-history')
            }}</b-nav-item>
            <b-nav-item class="mt-2" :to="{ name: 'Logout' }" exact-active-class="active">{{ $t('account.logout')
              }}</b-nav-item>

            <hr class="account-left-menu-separator">
            <b-nav-item class="mt-2" target="_blank" href="/how-to" exact-active-class="active">{{ $t('account.help-center')
              }}</b-nav-item>
          </b-nav>
        </b-col>

        <b-col md="9" class="account-content">
          <router-view></router-view>
        </b-col>
      </b-row>
    </div>
  </main>
</template>

<script>
export default {
  data: function () {
    return {
      mySubscriptionsTitle: ""
    }
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push({ name: 'Login' });
    },
    addStripe() {
      let hasStripeScript = false
      const stripeSrc = "https://js.stripe.com/v3/"
      for (let childIndex in document.head.children) {
        let child = document.head.children[childIndex]
        if (!(child instanceof HTMLScriptElement)) continue;

        let hasSrc = child.hasAttribute("src")
        let srcAttr = hasSrc && child.getAttribute("src")
        if (child && hasSrc && srcAttr == stripeSrc) {
          hasStripeScript = true;
          break;
        }
      }
      if (!hasStripeScript) {
        let stripeScript = document.createElement('script')
        stripeScript.setAttribute('src', stripeSrc)
        document.head.appendChild(stripeScript)
      }
    },
    updateMySubscriptionsTitle() {
      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/billing/subscription/current", {}, { headers: headers }).then(function (response) {
        let subscriptionArray = response.data
        let activeAmount = 0
        for (let i in subscriptionArray) {
          let sub = subscriptionArray[i]
          let active = sub.active

          if (active) activeAmount++
        }
        _this.mySubscriptionsTitle = _this.$t('my-subscriptions.title') + " (" + activeAmount + ")"
      }).catch(function (error) {

      });
    }
  },
  mounted() {
    this.addStripe()
    this.mySubscriptionsTitle = this.$t('my-subscriptions.title')
    this.updateMySubscriptionsTitle()
  }
}
</script>

<style lang="scss">
.account__nav {
  flex-direction: column;

  @extend .font-main;

  @media (min-width: $tablet-width) {
    flex-direction: row;
  }
}

.account-menu {
  font-weight: 400 !important;
  padding-top: 40px !important;
  position: fixed;
}

.account-container {
  max-width: 1280px;
  width: 100%;
  min-height: 800px;
  margin: auto;

  @media (max-width: $mobile-width-only) {
    max-width: none !important;
    min-width: 0 !important;
    margin: 0;
  }
}

.card-body h3 {
  font-weight: 600 !important;
}

.account-content {
  margin-bottom: 40px;
}

.account-content-container {
  min-height: 200px;
  position: relative;
  background-color: #00000008;
  border-radius: 10px;
  padding: 15px;
  min-height: calc(100vh - 500px);
}

.account-spinner-container {
  width: 100%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: center;
}

.account-card-header {
  font-size: 36px;
  font-weight: 500;
}

.account-header-container {
  position: relative;
  z-index: 1;
}
.account-left-menu-separator {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  margin-left: 15px;
  margin-right: 15px;
}


.account-create-item-button {
  min-width: 90px;
  margin-bottom: 20px;
  box-shadow: 0 14px 20px rgba(188, 68, 68, 0.06), 0 2px 6px rgba(188, 68, 68, 0.15), 0 0 1px rgba(188, 68, 68, 0.5);
  cursor: pointer;
  background-color: #d82d2d !important;
  border-radius: 20px !important;
  color: white !important;
  padding: 8px 15px !important;
  font-weight: 600 !important;
  display: inline-block;
  font-size: 18px;
  border: none !important;

  @media (max-width: $mobile-width-only) {
    width: 100% !important;
    margin-top: 10px;
    display: none !important;
  }

  @media (min-width: $tablet-width) {
    position: absolute;
    right: 0px;
    top: calc(50% - 12px);
    transform: translate(0px, -50%); 
  }
}
.account-create-item-button:hover {
  background-color: #d82d2df0 !important;
}
.account-create-item-button-plus {
  content: "";

  display: inline-block;
  width: 23px;
  height: 23px;
  margin-top: -3px;
  margin-right: 5px;
  background-size: contain;
  vertical-align: middle;

  background-image: url("~@/assets/plus.svg");
}

</style>