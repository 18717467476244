<template>
  <b-row class="admin-page">
    <b-col>
      <b-alert fade v-model="dismissSuccessMessageCountDown" class="position-fixed fixed-top text-center"
        style="z-index: 999999; top: 90px; margin: 0 auto; max-width: 750px" variant="success" dismissible>
        {{ successMessage }}
      </b-alert>
      <b-alert fade v-model="dismissErrorMessageCountDown" class="position-fixed fixed-top text-center"
        style="z-index: 999999; top: 90px; margin: 0 auto; max-width: 750px" variant="danger" dismissible>
        {{ errorMessage }}
      </b-alert>

      <div class="account-card-header">Admin</div>

      <TabSwitch ref="tabSwitch"></TabSwitch>

      <div class="account-content-container">

        <div v-show="showPageReports">
          <div class="admin-header">Reports</div>
          <div class="admin-horizontal-container">
            <b-button class="admin-button" variant="primary" :disabled="dailyIsSending" @click="sendDailyStats()">
              <span id="action-btn-span" class="text-white" v-if="!dailyIsSending">Send Daily Report</span>
              <b-spinner v-if="dailyIsSending" variant="light"></b-spinner>
            </b-button>
          </div>

          <br>
          <div class="admin-header">Analytics</div>
          <div class="admin-spinner-container">
            <b-spinner v-if="analyticsLoading" variant="dark"></b-spinner>
          </div>
          <div v-show="showAnalytics && !analyticsLoading" ref="analytics">
          </div>
        </div>


        <div v-show="showPageMap">
          <div class="admin-header">Location Review</div>
          <div class="admin-empty-container" v-if="modelsToReview.length == 0">
            No Models To Review
          </div>
          <div v-else>
            <b-table responsive striped hover :fields=modelsToReviewFields :items="modelsToReview" class="admin-table">
              <template #cell(link)="row"><span v-html="getModelLink(row.item)"></span></template>
              <template #cell(user)="row"><span v-html="getUserLink(row.item)"></span></template>
              <!--template #cell(email)="row"><span v-html="getModelUserEmail(row.item)"></span></template-->
              <template #cell(language)="row"><span v-html="getModelLanguage(row.item)"></span></template>
              <template #cell(status)="row"><span v-html="getModelStatus(row.item)"></span></template>
              <template #cell(actions)="row">
                <b-button size="sm" @click="onclickAcceptLocation(row.item)" class="mr-1 dmin-button btn-primary">
                  Accept
                </b-button>
                <b-button size="sm" @click="onClickDeclineLocation(row.item)"
                  class="mr-1 admin-subscription-delete-button">
                  Decline
                </b-button>
              </template>
            </b-table>
          </div>
        </div>


        <div v-show="showPageOther">
          <div class="admin-header">Other</div>
          <b-button class="admin-button" variant="primary" :disabled="isPopularRegenerating"
            @click="regeneratePopular()">
            <span id="action-btn-span" class="text-white" v-if="!isPopularRegenerating">Regenerate Popular</span>
            <b-spinner v-if="isPopularRegenerating" variant="light"></b-spinner>
          </b-button>
        </div>


        <div v-show="showPageUsers">
          <div class="admin-header">Users</div>

          <div class="admin-horizontal-container">
            <b-form-input class="admin-get-user-email-input" placeholder="Email or username"
              v-model="getUserInfoEmail"></b-form-input>
            <b-button class="admin-button" variant="primary" @click="onGetUserInfo()">
              <span id="action-btn-span" class="text-white" v-if="!gettingUserInfo">Get user info</span>
              <b-spinner v-if="gettingUserInfo" variant="light"></b-spinner>
            </b-button>
          </div>

          <div v-if="showSpinner" class="admin-spinner-wrapper">
            <div class="admin-spinner-container">
              <b-spinner class="admin-spinner" variant="dark"></b-spinner>
            </div>
          </div>
          <div v-show="showUserEditing">
            <br />
            <div class="admin-medium-header">User: <b>{{ editingUserEmail }}</b></div>
            <div class="admin-medium-header">Id: <b>{{ userData.id }}</b></div>
            <div class="admin-medium-header">Models: private <b>{{ userData.privateScenesAmount }}</b>, public:
              {{ userData.publicScenesAmount }}</div>
            <div class="admin-small-header">Current Subscriptions</div>
            <b-table responsive striped hover :fields=userInfoSubscriptionsFields :items="userInfoSubscriptions"
              class="admin-table">

              <template #cell(active)="row"><span :class="activeClass(row.item.active)"
                  v-html="row.item.active"></span></template>
              <template #cell(endTime)="row"><span v-html="row.item.endTime"></span></template>
              <template #cell(models)="row"><span v-html="currentModelsAmount(row.item)"></span></template>
              <template #cell(features)="row"><span v-html="row.item.features"></span></template>
              <template #cell(actions)="row">
                <b-button size="sm" @click="onClickDeleteSubscription(row.item)"
                  class="mr-1 admin-subscription-delete-button">
                  Delete
                </b-button>
                <br />
                <b-button size="sm" @click="onClickAddDaysToSubscription(row.item)"
                  class="mr-1 admin-subscription-add-button">
                  Add Days
                </b-button>
              </template>
            </b-table>

            <div class="admin-subcontainer">
              <div class="admin-medium-header">Add subscription</div>
              <div class="admin-add-subscription-container" ref="addSubscriptionContainer">
                <b-form-input class="admin-user-input" placeholder="Name" v-model="addSubscription_Name"></b-form-input>
                <b-form-input class="admin-user-input" placeholder="Amount"
                  v-model="addSubscription_Amount"></b-form-input>
                <b-form-checkbox class="admin-user-checkbox"
                  v-model="addSubscription_Configurator">Configurator</b-form-checkbox>
                <b-form-checkbox class="admin-user-checkbox"
                  v-model="addSubscription_Embedding">Embedding</b-form-checkbox>
                <b-form-checkbox class="admin-user-checkbox"
                  v-model="addSubscription_Analytics">Analytics</b-form-checkbox>
                <EditorSelector class="admin-user-selector" ref="addSubscriptionDuration"></EditorSelector>
                <b-button class="admin-button" variant="primary" :disabled="dailyIsSending"
                  @click="onAddSubscription()">
                  <span id="action-btn-span" class="text-white" v-if="!dailyIsSending">Add</span>
                  <b-spinner v-if="dailyIsSending" variant="light"></b-spinner>
                </b-button>
              </div>
            </div>

            <br />
            <div class="admin-medium-header">User Parameters:</div>

            <div class="admin-subcontainer" id="admin-roles-container">
              <div class="admin-medium-header">Roles:</div>
              <v-autocomplete chips deletable-chips multiple small-chips :items="userRoleItems"
                v-model="userRoles"></v-autocomplete>

              <div class="admin-medium-header">Other parameters:</div>

              <div v-for="item in userParams">
                <div class="admin-param-row">
                  <div class="admin-param-key" v-html="item.label"></div>

                  <EditorSelector v-if="item.isSwitch != undefined && item.isSwitch == true" class="admin-user-selector"
                    v-model="userData.user[item.key]" :titles="item.titles" :values="item.values"></EditorSelector>
                  <b-form-input v-else placeholder="" v-model="userData.user[item.key]"></b-form-input>

                  <div class="admin-param-right-label" v-html="item.rightLabel"></div>
                </div>
              </div>
              <br>
              <b-button class="admin-button" variant="primary" :disabled="isUserInfoSubmitting"
                @click="onSubmitUserInfo()">
                <span id="action-btn-span" class="text-white" v-if="!isUserInfoSubmitting">Submit User Parameters</span>
                <b-spinner v-if="isUserInfoSubmitting" variant="light"></b-spinner>
              </b-button>
            </div>

            <br>
            <b-button size="sm" @click="onClickDeleteUser()"
                  class="mr-1 admin-user-delete-button">
                  Delete User
                </b-button>

          </div>
        </div>

        <div v-show="showPageModels">
          <div class="admin-header">Models</div>
          <b-button class="admin-button" variant="primary" :disabled="gettingModelsWithFiles"
            @click="onGetModelsWithFiles()">
            <span id="action-btn-span" class="text-white" v-if="!gettingModelsWithFiles">Get Models With Files</span>
            <b-spinner v-if="gettingModelsWithFiles" variant="light"></b-spinner>
          </b-button>
          <div class="admin-empty-container"
            v-if="modelsWithFiles.length == 0 && !gettingModelsWithFiles && modelsWithFilesRequested">
            No Models
          </div>
          <div class="models-with-files" v-else-if="!gettingModelsWithFiles && modelsWithFilesRequested">
            <b-table responsive striped hover :fields=modelsWithFilesFields :items="modelsWithFiles"
              class="admin-table">
              <template #cell(link)="row"><span v-html="getModelLink(row.item)"></span></template>
              <template #cell(user)="row"><span v-html="getUserLink(row.item)"></span></template>
              <template #cell(downloadsCount)="row"><span v-html="getDownloadsCount(row.item)"></span></template>
              <!--template #cell(email)="row"><span v-html="getModelUserEmail(row.item)"></span></template-->
              <template #cell(language)="row"><span v-html="getModelLanguage(row.item)"></span></template>
              <template #cell(status)="row"><span v-html="getModelStatus(row.item)"></span></template>
              <template #cell(creationTime)="row"><span v-html="getModelCreationDate(row.item)"></span></template>
            </b-table>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>

import ApexCharts from 'apexcharts';
import dayjs from 'dayjs';
import moment from 'moment';
import { MODEL_FLAG } from "../../const";
import EditorSelector from '../Editor/EditorSelector.vue';
import TabSwitch from '../TabSwitch.vue';

export default {
  computed: {
    activeClass() {
      return function (active) {
        if (active == true) {
          return 'text-success'
        } else {
          return 'text-danger'
        }
      }
    }
  },
  data: function () {
    return {
      gettingModelsWithFiles: false,
      modelsWithFilesRequested: false,
      showSpinner: false,
      dismissSuccessMessageCountDown: 0,
      dismissErrorMessageCountDown: 0,
      successMessage: null,
      errorMessage: null,
      dailyIsSending: false,
      gettingUserInfo: false,
      getUserInfoEmail: "",
      editingUserEmail: "",
      showUserEditing: false,
      isPopularRegenerating: false,
      userRoles: [],
      userRoleItems: ["ROLE_USER", "ROLE_ADMIN", "ROLE_DEVELOPER"],
      isUserInfoSubmitting: false,
      userMaxModelSize: 0,
      showPageReports: false,
      showPageUsers: false,
      showPageMap: false,
      showPageOther: false,
      showPageModels: false,
      analyticsLoading: false,
      // Subscriptions
      userInfoSubscriptions: [],
      userInfoSubscriptionsFields: [
        { index: { label: "Index" } },
        { active: { label: "Active" } },
        { autoRenewal: { label: "Auto-Renewal" } },
        { endTime: { label: "End" } },
        { models: { label: "Models" } },
        { features: { label: "Features" } },
        { actions: { label: "Actions" } },
      ],
      userParams: [],
      userData: {},
      isAddingSubscription: false,
      addSubscription_Name: "Custom Bundle",
      addSubscription_Amount: 1,
      addSubscription_Configurator: false,
      addSubscription_Embedding: false,
      addSubscription_Analytics: false,
      addSubscription_duration: "month",
      modelsToReview: [],
      modelsToReviewFields: [
        { link: { label: "Link" } },
        { user: { label: "User" } },
        //{ email: { label: "Email" } },
        { language: { label: "Language" } },
        { status: { label: "Status" } },
        { actions: { label: "Actions" } }
      ],
      modelsWithFiles: [],
      modelsWithFilesFields: [
        { link: { label: "Link" } },
        { user: { label: "User" } },
        { downloadsCount: { label: "Downloads" } },
        //{ email: { label: "Email" } },
        { language: { label: "Language" } },
        { status: { label: "Status" } },
        { creationTime: { label: "Created" } }
      ]
    }
  },
  components: {
    EditorSelector,
    TabSwitch,
    ApexCharts
  },
  mounted() {
    document.title = "Admin"

    let _this = this

    this.$refs.tabSwitch.addTab({ title: "Users" })
    this.$refs.tabSwitch.addTab({ title: "Reports" })
    this.$refs.tabSwitch.addTab({ title: "Map" })
    this.$refs.tabSwitch.addTab({ title: "Models" })
    this.$refs.tabSwitch.addTab({ title: "Other" })
    this.$refs.tabSwitch.onSelect = this.onSelectTab
    this.$refs.tabSwitch.selectTab(0)
    this.onSelectTab(0)

    this.$refs.addSubscriptionDuration.changeCallback = function (value) {
      _this.addSubscription_duration = value;
    }

    this.$refs.addSubscriptionDuration.configure(["month", "3months", "halfyear", "year"], ["Month", "3 Months", "6 months", "Year"])
    this.$refs.addSubscriptionDuration.setSelected(this.addSubscription_duration)

    this.getModelsToReview()
  },
  updated() {
    document.title = "Admin"
  },
  methods: {
    onClickDeleteUser() {
      let _this = this;
      this.$root.$emit('showDialog', "Warning", "Do you really want to delete this user? This action cannot be undone!", "Cancel", "Delete user", function () {
        _this.deleteUser();
      })
    },
    deleteUser() {
      let _this = this
      let headers = this.getCommonHeaders()
      let params = {
        email: this.editingUserEmail
      }

      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/admin/deleteUser", {}, { params: params, headers: headers }
      ).then(function (response) {
        _this.onSelectTab(0)
        _this.showSuccessMessage("User Deleted!")
      }).catch(function (error) {
        _this.isUserInfoSubmitting = false
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    addAnalytics(type, data, loc) {
      let name = loc[type]

      var title = document.createElement('div')
      title.innerHTML = name
      title.className = "admin-analytics-chart-title"
      this.$refs.analytics.appendChild(title);


      let categories = []
      let chartData = []
      for (let date in data) {
        categories.push(new Date(date).getTime())
        chartData.push(data[date])
      }
      var max = 0
      for (let i in data) {
        let entries = data[i]
        if (entries > max) {
          max = entries
        }
      }
      var options = {
        chart: {
          //type: 'line',
          width: "100%",
          height: 160,
          type: 'area',
          stacked: false
        },
        series: [{
          name: name,
          data: chartData
        }],
        xaxis: {
          categories: categories,
          type: 'datetime'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 2,
          colors: ['black']
        },
        fill: {
          opacity: 0.5,
          colors: ['#111111']
        },
        yaxis: {
          max: max + 1,
          min: 0,
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          },
        },
        grid: {
          position: 'back'
        }
      }

      var chartContainer = document.createElement('div')
      chartContainer.className = "admin-analytics-chart-container"
      this.$refs.analytics.appendChild(chartContainer);

      var chart = new ApexCharts(chartContainer, options);

      chart.render();
    },
    showAnalytics(analyticsData) {
      this.$refs.analytics.innerHTML = ""

      for (let i in analyticsData.data) {
        this.addAnalytics(i, analyticsData.data[i], analyticsData.loc)
      }
    },
    loadAnalytics() {
      let _this = this
      let headers = this.getCommonHeaders()
      let params = { days: 90 }
      this.analyticsLoading = true
      this.axios.get(
        process.env.VUE_APP_API_BASEURL + "/api/v1/admin/getAnalytics", { params: params, headers: headers }
      ).then(function (response) {
        _this.analyticsLoading = false
        _this.showAnalytics(response.data)
      }).catch(function (error) {
        _this.analyticsLoading = false
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    getUserField(key) {
      return this.userData.user[key]
    },
    onSelectTab(index) {
      this.showPageUsers = index == 0
      this.showPageReports = index == 1
      this.showPageMap = index == 2
      this.showPageModels = index == 3
      this.showPageOther = index == 4
    },
    onGetModelsWithFiles() {
      this.getModelsWithFiles()
    },
    onclickAcceptLocation(model) {
      let _this = this
      let headers = this.getCommonHeaders()
      let params = { hash: model.hash, accept: true, comment: "" }
      this.axios.get(
        process.env.VUE_APP_API_BASEURL + "/api/v1/admin/reviewLocation", { params: params, headers: headers }
      ).then(function (response) {
        _this.showSuccessMessage("Review is sent")
        _this.getModelsToReview()
      }).catch(function (error) {
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    onClickDeclineLocation(model) {
      let _this = this;
      this.$root.$emit('showDialogWithInput', "Warning", "Add comments that will be visible to the user", "", "Cancel", "Send", function (text) {
        _this.declineLocation(model, text)
      })
    },
    declineLocation(model, comment) {
      let _this = this
      let headers = this.getCommonHeaders()
      let params = { hash: model.hash, accept: false, comment: comment }
      this.axios.get(
        process.env.VUE_APP_API_BASEURL + "/api/v1/admin/reviewLocation", { params: params, headers: headers }
      ).then(function (response) {
        _this.showSuccessMessage("Review is sent")
        _this.getModelsToReview()
      }).catch(function (error) {
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    getModelLanguage(model) {
      if (model.user.lang) {
        return model.user.lang
      } else {
        return 'undefined'
      }
    },
    getModelCreationDate(model) {
      let time = dayjs(model.createTime * 1000).format('DD.MM.YYYY')
      return time
    },
    getModelStatus(model) {
      return model.flags.includes(MODEL_FLAG.IS_PRIVATE) ? "Private" : "Public"
    },
    getModelsWithFiles() {
      this.gettingModelsWithFiles = true

      let _this = this
      let headers = this.getCommonHeaders()
      let params = {}
      this.axios.get(
        process.env.VUE_APP_API_BASEURL + "/api/v1/admin/getAllModelsWithFiles", { params: params, headers: headers }
      ).then(function (response) {
        _this.gettingModelsWithFiles = false
        _this.modelsWithFilesRequested = true
        _this.modelsWithFiles = response.data
      }).catch(function (error) {
        _this.gettingModelsWithFiles = false
        _this.modelsWithFilesRequested = true
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    getModelsToReview() {
      let _this = this
      let headers = this.getCommonHeaders()
      let params = {}
      this.axios.get(
        process.env.VUE_APP_API_BASEURL + "/api/v1/admin/locationReviewList", { params: params, headers: headers }
      ).then(function (response) {
        _this.modelsToReview = response.data
      }).catch(function (error) {
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    getDownloadsCount(model) {
      return model.downloadsCount
    },
    getModelUserEmail(model) {
      return model.user.email
    },
    getModelLink(model) {
      let link = this.generateSceneLink(model.hash, false)
      if (link.includes("?")) {
        link += "&"
      } else {
        link += "?"
      }
      let linkWithToken = link + "token=" + this.$store.state.token
      return "<a class='admin-link' href='" + linkWithToken + "' target='_blank' >" + link + "</a>"
    },
    getUserLink(model) {
      let link = this.getURLForProfile(model.user.username)
      let nameTrimmed = model.user.name.substring(0, 10)
      let name = nameTrimmed
      if (model.user.name.length != nameTrimmed.length) {
        name += "..."
      }
      return name + "<br><a class='admin-link' href='" + link + "' target='_blank' >To Profile</a>"
    },
    currentModelsAmount(item) {
      return item.models.length + "/" + item.product.modelsAmount
    },
    onSubmitUserInfo() {
      this.isUserInfoSubmitting = true

      let _this = this
      let headers = this.getCommonHeaders()
      let data = {
        roles: this.userRoles.toString(),
        email: this.editingUserEmail,
        maxFileSize: this.userData.user.maxFileSize * 1000 * 1000,
        accountType: this.userData.user.accountType
      }

      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/admin/updateUserInfo", data, { headers: headers }
      ).then(function (response) {
        _this.isUserInfoSubmitting = false
        _this.showSuccessMessage("Updated!")
      }).catch(function (error) {
        _this.isUserInfoSubmitting = false
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    regeneratePopular() {
      this.isPopularRegenerating = true

      let _this = this
      let headers = this.getCommonHeaders()

      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/admin/regeneratePopular", {}, { headers: headers }
      ).then(function (response) {
        let stat = eval(response.data)
        _this.isPopularRegenerating = false
        _this.showSuccessMessage("Popular is regenerated: " + JSON.stringify(stat, null, 4))
      }).catch(function (error) {
        _this.isPopularRegenerating = false
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    onAddSubscription() {
      let _this = this;
      let headers = this.getCommonHeaders()

      let params = {
        email: this.editingUserEmail,
        name: this.addSubscription_Name,
        amount: this.addSubscription_Amount,
        configurator: this.addSubscription_Configurator,
        analytics: this.addSubscription_Analytics,
        embedding: this.addSubscription_Embedding,
        period: this.addSubscription_duration
      }
      this.axios.get(
        process.env.VUE_APP_API_BASEURL + "/api/v1/admin/addSubscription", { params: params, headers: headers }
      ).then(function (response) {
        _this.onGetUserInfo()
      }).catch(function (error) {
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    deleteSubscription(sub) {
      this.showSpinner = true
      this.showUserEditing = false;

      let _this = this;
      let headers = this.getCommonHeaders()

      this.axios.get(
        process.env.VUE_APP_API_BASEURL + "/api/v1/admin/deleteSubscription", { params: { "id": sub.id }, headers: headers }
      ).then(function (response) {
        _this.onGetUserInfo()
      }).catch(function (error) {
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    onClickDeleteSubscription(sub) {
      let _this = this;
      this.$root.$emit('showDialog', "Warning", "Remove this subscription?", "Cancel", "Delete", function () {
        _this.deleteSubscription(sub)
      })
    },
    onClickAddDaysToSubscription(sub) {
      let _this = this;
      this.$root.$emit('showDialogWithInputSingleLine', "Add Days To Subscription", "", "", this.loc("popup-cancel"), "Add", function (text) {
        _this.addDaysToSubscription(sub, text)
      })
    },
    addDaysToSubscription(sub, days) {
      debugger
      this.showSpinner = true
      this.showUserEditing = false;

      let _this = this;
      let headers = this.getCommonHeaders()

      this.axios.get(
        process.env.VUE_APP_API_BASEURL + "/api/v1/admin/addDaysToSubscription", { params: { "id": sub.id, "amount": parseInt(days) }, headers: headers }
      ).then(function (response) {
        _this.onGetUserInfo()
      }).catch(function (error) {
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    updateEditinfUserInfo(info) {
      let subscriptions = info.subscriptions
      for (let subIndex in subscriptions) {
        let sub = subscriptions[subIndex]
        let expireDate = new Date(sub.endTime)
        sub.endTime = moment(sub.endTime).format('MM.DD.YYYY HH:mm') + "<b> (" + dayjs(expireDate).fromNow() + ")</b>"
        sub.index = Number(subIndex) + 1

        let features = "none"
        if (sub.product.enableConfigurator || sub.product.enableEmbedding || sub.product.enableAnalytics) {
          features = ""
          let arr = []
          if (sub.product.enableConfigurator) {
            arr.push(this.loc("store.bundle.configurator"))
          }
          if (sub.product.enableEmbedding) {
            arr.push(this.loc("store.bundle.website-embed"))
          }
          if (sub.product.enableAnalytics) {
            arr.push(this.loc("store.bundle.analytics"))
          }
          features = arr.join("<br>")
        }
        sub.features = features
      }
      this.userInfoSubscriptions = subscriptions
    },
    onGetUserInfo() {
      this.gettingUserInfo = true
      this.showUserEditing = false

      let _this = this
      let headers = this.getCommonHeaders()

      this.axios.get(
        process.env.VUE_APP_API_BASEURL + "/api/v1/admin/getUserInfo", { params: { "email": this.getUserInfoEmail }, headers: headers }
      ).then(function (response) {
        _this.showSpinner = false
        _this.updateEditinfUserInfo(response.data)

        _this.userData = response.data

        _this.editingUserEmail = response.data.user.email
        _this.gettingUserInfo = false
        _this.showUserEditing = true
        _this.userData.user.maxFileSize = _this.userData.user.maxFileSize / (1024 * 1024); // MB
        _this.userParams = [
          { label: "Max File Size", key: "maxFileSize", rightLabel: "MB" },
          { label: "Account Type", key: "accountType", rightLabel: "", isSwitch: true, titles: ["Standard", "Business", "Culture"], values: [0, 1, 2] }
        ]

        let roles = []
        for (let roleInfoIndex in response.data.roles) {
          let roleInfo = response.data.roles[roleInfoIndex]
          roles.push(roleInfo.role)
        }
        _this.userRoles = roles
      }).catch(function (error) {
        _this.gettingUserInfo = false
        _this.showUserEditing = false
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    },
    showErrorMessage(msg) {
      this.dismissErrorMessageCountDown = 5
      this.errorMessage = msg
    },
    showSuccessMessage(msg) {
      this.dismissSuccessMessageCountDown = 5
      this.successMessage = msg
    },
    sendDailyStats() {
      this.dailyIsSending = true
      this.userInfoSubscriptions = []

      let _this = this
      let headers = this.getCommonHeaders()

      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/admin/sendStats", {}, { headers: headers }
      ).then(function (response) {
        _this.dailyIsSending = false
        _this.showSuccessMessage("Email is sent!")
      }).catch(function (error) {
        _this.dailyIsSending = false
        _this.sceneCreationInProcess = false
        _this.showErrorMessage(error.response ? error.response.data.cause || error.message : "Unknown error")
      })
    }
  },
  watch: {
    showPageReports(newValue, oldValue) {
      if (newValue == false) return;

      this.loadAnalytics()
    }
  }
}
</script>

<style>
#send-daily-button {
  min-width: 100px;
}

.admin-daily-label {
  font-size: 20px;
  margin-right: 15px;
}

.admin-daily-container {
  display: flex;
  align-items: center;
}

.admin-page {
  min-height: calc(100vh - 300px);
}

.admin-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  line-height: 30px;
  padding-left: 5px;
  margin-bottom: 10px;
  font-weight: 500;
}

.admin-horizontal-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.admin-subscribe-email-input {
  max-width: 200px;
}

.admin-subscribe-amount-input {
  max-width: 130px;
}

.admin-get-user-email-input {
  width: 300px;
  max-width: 300px;
}

.admin-user-input {
  width: 300px;
  max-width: 300px;
}

.admin-medium-header {
  margin-top: 0px;
  font-weight: 500;
  font-size: 18px;
}

.admin-small-header {
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
}

.admin-table {
  min-height: 150px;
  max-height: 600px;
  overflow: auto;
  border: 1px solid rgb(220, 220, 220);
  margin-top: 10px;
}

.admin-table table {
  margin: 0px !important;
}

.admin-subscription-delete-button {
  width: 100%;
  color: red !important;
}

.admin-subscription-add-button {
  margin-top: 5px !important;
  width: 100%;
  color: black !important;
}

.admin-user-delete-button {
  color: white !important;
  background-color: red !important;
}

.admin-subcontainer {
  border: 1px solid rgb(220, 220, 220);
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  max-width: 300px;
  padding: 10px;
}

#admin-roles-container {
  max-width: 100% !important;
}

.admin-add-subscription-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.admin-user-selector {}

.admin-spinner {
  align-self: center;
}

.admin-spinner-container {
  width: 100%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: center;
}

.admin-spinner-wrapper {
  position: relative;
  min-height: 200px;
}

.admin-link {
  color: #5ab5ba;
  text-decoration: underline;
}

.admin-empty-container {
  height: 50px;
  padding-left: 5px;
}

.admin-param-row {
  display: grid;
  height: 40px;
  grid-gap: 2%;
  grid-template-columns: 15% 30% 10%;
}

.admin-param-key {
  line-height: 40px;
  text-align: right;
}

.admin-param-right-label {
  line-height: 40px;
  text-align: left;
}

.admin-analytics-chart-title {
  padding-left: 15px;
  margin-top: 25px;
  font-size: 24px;
}
.admin-analytics-chart-container {
  width: 930px;
}
</style>
