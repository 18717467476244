<template>
  <b-row>
    <b-col>
      <b-alert fade v-model="dismissSuccessMessageCountDown" class="position-fixed fixed-top text-center" style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="success" dismissible>
        {{successMessage}}
      </b-alert>      
      <b-alert fade v-model="dismissErrorMessageCountDown" class="position-fixed fixed-top text-center" style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="danger" dismissible>
        {{errorMessage}}
      </b-alert>      

      <div class="account-card-header">{{ $t('my-scenes.title') }}</div>
      <b-button
        class="create-model-button"
        id="create-model-button-my-scenes-mobile"
        :disabled="sceneCreationInProcess"
        @click="onCreateScene"
        >
          <div id="create-model-button-plus" v-if="!sceneCreationInProcess"></div>
          <span class="text-white" v-if="!sceneCreationInProcess">{{ $t('my-scenes.create-new') }}</span>
          <b-spinner v-if="sceneCreationInProcess" variant="light"></b-spinner>
      </b-button>

      <div class="my-scenes-top-menu">
        <div @click="onClickAll()" id="my-scenes-top-menu-all-btn" class="my-scenes-top-menu-item my-scenes-top-menu-item-on">
          <div></div>
          <div id="my-scenes-top-menu-all-label">{{ $t('my-scenes.tab-all') }}</div>
        </div>
        <div @click="onClickPublic()" id="my-scenes-top-menu-public-btn" class="my-scenes-top-menu-item my-scenes-top-menu-item-off">
          <div></div>
          <div id="my-scenes-top-menu-public-label"></div>
        </div>

        <b-button
          class="create-model-button"
          id="create-model-button-my-scenes"
          :disabled="sceneCreationInProcess"
          @click="onCreateScene"
          >
            <div id="create-model-button-plus" v-if="!sceneCreationInProcess"></div>
            <span class="text-white" v-if="!sceneCreationInProcess">{{ $t('my-scenes.create-new') }}</span>
            <b-spinner v-if="sceneCreationInProcess" variant="light"></b-spinner>
        </b-button>
      </div>

      <transition name="my-model-list-fade">
        <div class="account-content-container">
          <SceneGrid v-show="!modelListLoadingInProcess" ref="sceneGrid"></SceneGrid>
          <div class="account-spinner-container">
            <b-spinner v-if="modelListLoadingInProcess" variant="dark"></b-spinner>
          </div>
        </div>
      </transition>

    </b-col>
  </b-row>
</template>

<script>

import SceneGrid from '../SceneGrid.vue';

export default {
  data: function() {
    return {
      modelListLoadingInProcess: false,
      showModelList: false,
      dismissSuccessMessageCountDown: 0,
      dismissErrorMessageCountDown: 0,
      successMessage: null,
      errorMessage: null,
      sceneCreationInProcess: false,
      maxModelsCountPrivate: 0,
      currentModelTotalCount: 0
    }
  },
  components: {
    SceneGrid
  },
  mounted() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["my-scenes"];
    this.initModelList(false)
    this.fetchUserInfo();

    this.$refs.sceneGrid.setOnSelectItem(this.onSelectItem)
    this.$refs.sceneGrid.showPublicLabel = true
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["my-scenes"];
  },
  methods: {
    onSelectItem(item) {
      window.open(this.getURLForSceneEdit(item.scene.hash), "_self")
    },
    onClickAll() {
      var allMenuItemLabelElement = document.getElementById('my-scenes-top-menu-all-btn');
      var publicMenuItemLabelElement = document.getElementById('my-scenes-top-menu-public-btn');

      allMenuItemLabelElement.className = "my-scenes-top-menu-item my-scenes-top-menu-item-on";
      publicMenuItemLabelElement.className = "my-scenes-top-menu-item my-scenes-top-menu-item-off";

      this.initModelList(false)
    },
    onClickPublic() {
      var allMenuItemLabelElement = document.getElementById('my-scenes-top-menu-all-btn');
      var publicMenuItemLabelElement = document.getElementById('my-scenes-top-menu-public-btn');
      
      allMenuItemLabelElement.className = "my-scenes-top-menu-item my-scenes-top-menu-item-off";
      publicMenuItemLabelElement.className = "my-scenes-top-menu-item my-scenes-top-menu-item-on";

      this.initModelList(true)
    },
    updateHeader() {
      // Nikita: This code I wrote in Mtskheta, Georgia, on 9 April 2023
      //var sceneListElement = document.getElementById('my-scenes-title');
      //var title = this.$root.$i18n.messages[this.$root.$i18n.locale]["my-scenes"]["title"]
      //if (this.maxModelsCountPrivate > 0) {
      //  title += " (" + this.currentModelTotalCount + "/" + this.maxModelsCountPrivate + ")"
      //}
      //sceneListElement.innerHTML = title
    },
    onCreateScene() {
      this.sceneCreationInProcess = true
      let _this = this;
      let headers = this.getCommonHeaders();

      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/models/security", {}, {headers: headers}).then(function(response) {
        _this.sceneCreationInProcess = false
        window.location.href = _this.getURLForSceneEdit(response.data.hash)
      }).catch(function (error) {
        _this.sceneCreationInProcess = false;
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        console.log("error", error);
      });
    },
    fetchUserInfo() {
      var publicMenuItemLabelElement = document.getElementById('my-scenes-top-menu-public-label');
      var allMenuItemLabelElement = document.getElementById('my-scenes-top-menu-all-label');

      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/user/security", {headers: headers}).then(function(response) {
        _this.maxModelsCountPrivate = response.data.maxModelsCountPrivate;
        _this.currentModelTotalCount = response.data.currentModelTotalCount;
        _this.updateHeader();

        allMenuItemLabelElement.innerHTML    = _this.$i18n.t('my-scenes.tab-all') + " (" + response.data.currentModelTotalCount + "/" + response.data.maxModelsCountPrivate + ")"; 
        publicMenuItemLabelElement.innerHTML = _this.$i18n.t('my-scenes.tab-public') + " (" + response.data.currentPublicModelCount + "/" + response.data.maxModelsCount + ")"; 
      }).catch(function (error) {

      });
    },
    initModelList(isPublicOnly) {
      let _this = this;
      let headers = this.getCommonHeaders();
      
      if (isPublicOnly) {
        this.$refs.sceneGrid.noModelsText = "No public 3D scenes yet. <br>To make a 3D scene public, uncheck it's <b>Private</b> option in the web editor";
      } else {
        this.$refs.sceneGrid.noModelsText = "No 3D scenes yet. Press <b>Create new 3D scene</b> to add one"
      }

      this.modelListLoadingInProcess = true;

      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/models/my", { params: {}, headers: headers}).then(function(response) {
        let sceneArray = response.data.value || response.data;
        _this.$refs.sceneGrid.configure(sceneArray, isPublicOnly)

        _this.modelListLoadingInProcess = false;
        _this.showModelList = true;
      }).catch(function (error) {
        _this.showModelList = true;
        _this.modelListLoadingInProcess = false;
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        console.log("error", error);
      });
    }
  }
}
</script>

<style lang="scss">
#create-model-button-my-scenes {
  @media (min-width: $tablet-width) {
    position: absolute;
    right: 0px;
    top: calc(50% - 12px);
    transform: translate(0px, -50%); 
  }

  @media (max-width: $mobile-width-only) {
    margin-top: 10px;
    display: none !important;
  }
}
#create-model-button-my-scenes-mobile {
  @media (min-width: $tablet-width) {
    display: none !important;
  }

  margin-bottom: 10px !important;
}
.profile-models-item-separator {
  height: 1px;
  margin-bottom: 10px;
  background-color: #e3e3e3;
}
#profile-models-spinner {
  left: 50%;
  margin-left: -0.75rem;
  position: absolute;
  margin-top: 30px;
}
.card-body {
  max-height: none !important;
}
#my-models-spinner-container {
  height: 50px;
}
.my-model-list-fade-enter-active, .my-model-list-fade-leave-active {
  transition: opacity .5s;
}
.my-model-list-fade-enter, .my-model-list-fade-leave-to {
  opacity: 0;
}
.my-scenes-top-menu {
  border-bottom: 1px solid #bcbcbc;
  margin-bottom: 15px;  
  margin-top: 10px;
  position: relative;

  @media (max-width: $mobile-width-only) {
    margin-bottom: 10px !important;  
  }
}

.my-scenes-top-menu-item {
  display: inline-block;

  font-size: 20px;
  font-weight: 500;
  padding: 0px 10px;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: $mobile-width-only) {
    font-size: 14px !important;
  }
}

.my-scenes-top-menu-item-off {
  
}
.my-scenes-top-menu-item-off:hover {
  border-bottom: 4px solid #a8d64c44;
}
.my-scenes-top-menu-item-on {
  border-bottom: 4px solid #a8d64c;
}

</style>
