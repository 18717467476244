<template>
  <b-row>
    <b-col>
      <div class="store-container">
        <div class="account-card-header">{{ $t('my-subscriptions.title') }}</div>
        <TabSwitch id="store-items-switch" ref="itemsSwitch"></TabSwitch>

        <div class="account-content-container">
          <div ref="emptyLabel" v-show="showEmptyText && !isLoadingSubscriptions"
            class="store-active-subscriptions-empty"></div>
          <div class="account-spinner-container">
            <b-spinner v-if="isLoadingSubscriptions" variant="dark"></b-spinner>
          </div>

          <div v-show="!isLoadingSubscriptions" class="store-current-items" ref="currentItemsContainer"></div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>

import TabSwitch from '../TabSwitch.vue';

export default {
  data: function () {
    return {
      showEmptyText: false,
      isLoadingSubscriptions: false
    }
  },
  components: {
    TabSwitch
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["my-subscriptions"];
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["my-subscriptions"];
  },
  mounted() {
    this.$refs.itemsSwitch.addTab({ title: this.$t("my-subscriptions.active") })
    this.$refs.itemsSwitch.addTab({ title: this.$t("my-subscriptions.expired") })
    this.$refs.itemsSwitch.onSelect = this.onSelectTab
    this.$refs.itemsSwitch.selectTab(0)

    this.updateCurrentSubscriptions(true, true)
  },
  methods: {
    onSelectTab(tab) {
      if (tab == 0) {
        this.updateCurrentSubscriptions(true, true)
      } else if (tab == 1) {
        this.updateCurrentSubscriptions(false, true)
      }
    },
    loc(key) {
      return this.$t(key)
    },
    configureCurrentSubscriptions(subscriptionArray, isOnlyActive, addItems) {
      
      if (addItems) {
        this.$refs.currentItemsContainer.innerHTML = ""
      }

      let activeAmount = 0
      let expiredAmount = 0
      for (let i in subscriptionArray) {
        let sub = subscriptionArray[i]
        if (sub.status == "created") continue; // skip incomplete sub

        let active = sub.active

        if (active) activeAmount++
        if (!active) expiredAmount++

        if (isOnlyActive != active) continue

        if (addItems) {
          let item = this.createSubscriptionItem(sub)
          this.$refs.currentItemsContainer.appendChild(item.$el)
        }
      }

      if (addItems) {
        if (isOnlyActive) {
          this.showEmptyText = activeAmount == 0
          this.$refs.emptyLabel.innerHTML = this.$t('store.active-subscriptions-empty')
        } else {
          this.showEmptyText = expiredAmount == 0
          this.$refs.emptyLabel.innerHTML = this.$t('store.expired-subscriptions-empty')
        }
      }
      this.$refs.itemsSwitch.setTabTitle(0, this.$t("my-subscriptions.active") + " (" + activeAmount + ")")
      this.$refs.itemsSwitch.setTabTitle(1, this.$t("my-subscriptions.expired") + " (" + expiredAmount + ")")
    },
    updateCurrentSubscriptions(isActive, addItems) {
      this.isLoadingSubscriptions = true

      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/billing/subscription/current", {}, { headers: headers }).then(function (response) {
        let subscriptionArray = response.data
        _this.configureCurrentSubscriptions(subscriptionArray, isActive, addItems)
        _this.isLoadingSubscriptions = false
      }).catch(function (error) {
        console.log("error", error);
        _this.isLoadingSubscriptions = false
      });
    }
  }
}  
</script>

<style>

.store-active-subscriptions-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.account-card-subheader {
  font-size: 20px;
  padding-left: 0px;
  padding-bottom: 2px;
}
</style>