<template>
  <div class="scene-promo-item-container">
    <div class="scene-promo-top-container">
      <div class="scene-promo-title" ref="scenePromoTitle"></div>
      <div class="scene-promo-show-qr" @click="onPressShowQR">{{ showQRText }}</div>
      <div class="scene-promo-delete" @click="onPressDeletePromo">{{ deletePromoText }}</div>
    </div>
    <div class="scene-promo-item-top-row">
      <a ref="linkContainer" target="_blank" class="scene-promo-item-link-container">
        <transition name="fast-transition">
          <img ref="image" class="scene-promo-item-img"
            onerror="this.onerror=null; this.src='/img/placeholder_16_9.jpg'" src="/img/placeholder_16_9.jpg" />
        </transition>
        <div class="scene-promo-item-icons-container">
          <img class="scene-promo-item-icon" src="@/assets/scene_item_on_map.svg"
            v-show="hasGeolocation && showMapIcons" v-b-tooltip.hover.top.html.window="onMapTooltip">
          <img class="scene-promo-item-icon" src="@/assets/scene_item_public_on_map.svg"
            v-show="hasPublishedOnMap && showMapIcons" v-b-tooltip.hover.top.html.window="onPublishedOnMapTooltip">
          <img class="scene-promo-item-icon" src="@/assets/scene_item_configurator.svg" v-show="hasConfigurator"
            v-b-tooltip.hover.top.html.window="configuratorTooltip" />
        </div>
        <div v-show="isPublic && showPublicLabel" class="scene-promo-item-corner-label">
          public
        </div>
      </a>


      <div id="chart" ref="chart" class="scene-promo-item-chart">

      </div>
    </div>

    <transition name="fast-transition">
      <img v-show="false" ref="qrImage" class="scene-promo-item-qr"
        onerror="this.onerror=null; this.src='/img/placeholder_16_9.jpg'" src="/img/placeholder_16_9.jpg" />
    </transition>
  </div>
</template>

<script>

import ApexCharts from 'apexcharts';

export default {
  data: function () {
    return {
      isPublic: false,
      showPublicLabel: true,
      promo: null,
      hasImage: false,
      hasAnnotations: false,
      hasConfigurator: false,
      hasPublishedOnMap: false,
      configuratorTooltip: "Configurator",
      onMapTooltip: "Geolocation",
      onPublishedOnMapTooltip: "Published On Map",
      hasGeolocation: false,
      showMapIcons: true,
      onSelectItem: null,
      showQRText: "Show QR",
      deletePromoText: "Delete",
      chart: null,
      onDeleteItem: null
    }
  },
  components: {
    ApexCharts
  },
  mounted() {
    this.showQRText = this.loc('scene-promo.show-qr')
    this.deletePromoText = this.loc('scene-promo.delete')
  },
  updated() {
    if (!this.chart) {
      let options = this.buildAnalyticsOptions(this.promo.analytics)
      var chart = new ApexCharts(this.$refs.chart, options);

      chart.render();

      this.chart = chart
    }
  },
  methods: {
    configure(promo) {
      let img = this.$refs.image

      let locale = this.getModelLocale(promo.model)
      this.hasImage = this.setupDefaultImagePreviewForModel(img, promo.model)

      this.$refs.scenePromoTitle.innerHTML = promo.name

      this.promo = promo
      this.$refs.linkContainer.href = this.getURLForScene(promo.hash)
    },
    onPressShowQR() {
      this.showShareSceneWindow(this.promo.hash)
    },
    onPressDeletePromo() {
      if (this.onDeleteItem) {
        this.onDeleteItem(this.promo)
      }
    }
  }
}
</script>

<style lang="scss">
.scene-promo-item-info-container {
  min-height: 30px;
}

.scene-promo-item-stats-section {
  position: relative;
}

.scene-promo-item-container {
  position: relative;
  border-radius: 10px;
  background-color: #c4c4c44f;
  padding: 10px 10px 0px 10px;
  height: 250px;
}

.scene-promo-item-link-container {
  position: absolute;
  display: block;
  width: 190px;
  height: 190px;
}

.scene-promo-item-img {
  background-color: #FAFAFA;
  border-radius: 10px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
  object-fit: cover;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.scene-promo-item-statistics {
  font-weight: 500;
  right: 5;
  text-align: right;
  position: absolute;
  line-height: 35px;
}

.scene-promo-item-name {
  width: 100%;
  padding-right: 45px;
  padding-left: 5px;
  line-height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Helvetica, sans serif;
}

.scene-promo-item-statistics::before {
  content: "";

  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -3px;
  margin-right: 3px;
  background-size: contain;
  vertical-align: middle;

  background-image: url("~@/assets/views.svg");
}

.scene-promo-item-selection-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.scene-promo-item-icons-container {
  position: absolute;
  display: flex;
  left: 10px;
  top: 10px;
}

.scene-promo-item-icon {
  width: 25px;
  height: 25px;
  -webkit-filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, .4));
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, .4));
  opacity: 0.7;
}

.scene-promo-item-corner-label {
  position: absolute;
  left: 7px;
  bottom: 7px;
  background-color: #eaac40;
  border-radius: 6px;
  color: black;
  padding: 0px 7px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2));
}

.scene-promo-item-chart {
  margin-left: 200px;
  width: calc(100% - 25px);

  background-color: #ffffff33;
  border: 1px solid #0000000f;
  border-radius: 9px;
  padding: 5px;
  height: 190px;
}

.scene-promo-item-qr {
  position: absolute;
  right: 10px;
  height: 140px;
  width: 160px;
  top: 10px;
  border-radius: 5px;
}

.apex-chart {
  width: 100px;
  display: block;
  height: 100px;
}

.scene-promo-item-top-row {
  display: flex;
}

.scene-promo-title {
  font-size: 18pt;
  line-height: 34px;
}

.scene-promo-show-qr {
  padding: 3px 10px;
  cursor: pointer;
  box-shadow: none;
  background-color: #59b5bb33;
  color: #59b5bb !important;
  border: 2px solid #59b5bb !important;
  border-radius: 20px !important;
  font-weight: 600;
  text-align: center;
}

.scene-promo-delete {
  padding: 3px 10px;
  cursor: pointer;
  box-shadow: none;
  background-color: #d82d2c21;
  color: #d82d2c !important;
  border: 2px solid #d82d2c !important;
  border-radius: 20px !important;
  font-weight: 600;
  text-align: center;
}

.scene-promo-top-container {
  display: flex;
  column-gap: 10pt;
  margin-bottom: 5pt;
}
</style>