import axios from "axios"
import { BootstrapVue, IconsPlugin, ModalPlugin } from "bootstrap-vue"
import Vue from "vue"
import VueAxios from "vue-axios"
import VueI18n from "vue-i18n"
import linkify from "vue-linkify"
import * as VueGoogleMaps from 'vue2-google-maps'
import App from "./App.vue"
import { MAP_KEY } from "./const"
import en from "./lang/en.json"
import ru from "./lang/ru.json"
import router from "./router"
import store from "./store"
import { getLang } from "./utils/getLang"
import StoreBundleItem from "./views/StoreBundleItem.vue"

import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { API_URL } from "./const.js"
import Map from "./views/Map/Map.vue"

Vue.use(VueI18n)

Vue.directive("linkified", linkify)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(ModalPlugin)
Vue.config.productionTip = false
Vue.config.silent = true
Vue.use(Vuetify)

Vue.use(VueGoogleMaps, {
  load: {
    key: MAP_KEY,
    libraries: 'geocoding',
  },
  installComponents: true
});

const i18n = new VueI18n({
  locale: getLang(),
  messages: {
    en,
    ru,
  },
})

Vue.mixin({
  data: function () {
    return {
      editor: "editor",
    }
  },
  methods: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) {
        return "Android"
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS"
      }

      if (
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0) ||
        navigator.platform === "iPad"
      ) {
        return "iOS"
      }

      return "unknown"
    },
    generateSceneLink(hash, allowEngine) {
      let link = ""
      if (this.$archengine && allowEngine) {
        link = this.$archengine.ccall('callFromJS', 'string', ['string'], ["generateLink"]);
        link = eval(link)
      } else {
        link = process.env.VUE_APP_API_BASEURL + "/c/" + hash
      }
      return link
    },
    randomString() {
      var str = ""
      for (var i = 0; i < 4; i++) {
        str += (Math.random().toString(16) + "000000000").substr(2, 8)
      }
      return str
    },
    getURLForSceneEdit(sceneHash) {
      var link = "/editor/" + sceneHash

      // Pass custom server api url in development mode for testing/debugging
      if (process.env.NODE_ENV === "development") {
        link += "?api=" + process.env.VUE_APP_API_BASEURL
        link += "&website-url=" + process.env.VUE_APP_WEBSITE_URL
      }
      return link
    },
    getURLForCollectionEdit(hash) {
      var link = "/account/collection/edit/" + hash
      return link
    },
    getURLForProfile(username) {
      return "/" + username
    },
    getURLForScene(hash) {
      return "/scene/" + hash
    },
    getModelImageURL(model, imageName) {
      if (model.images.length == 0) return ""

      let result = ""
      let hasImage = false
      for (var i in model.images) {
        var image = model.images[i]
        if (image.url.length > 0 && image.name == imageName) {
          hasImage = true

          if (process.env.NODE_ENV === "production") {
            // use CDN
            result = image.url

          }
          break
        }
      }

      if (hasImage && process.env.NODE_ENV != "production") {
        result =
          process.env.VUE_APP_API_BASEURL +
          "/api/v1/files/imageByModelHash?modelHash=" +
          model.hash +
          "&fileName=" + imageName
      }

      return result
    },
    getQRImageURL(model) {
      let result = ""
      if (process.env.NODE_ENV === "production") {
        result = model.qrLink
      } else {
        result =
          process.env.VUE_APP_API_BASEURL +
          API_URL.DOWNLOAD_QR + "?hash=" +
          model.hash
      }

      return result
    },
    setupDefaultImagePreviewForModel(imageElement, model) {
      let url = this.getModelImageURL(model, "photo_medium.jpeg")
      imageElement.src = url
      return url && url.length > 0
    },
    setupDefaultQRForModel(imageElement, model) {
      let url = this.getQRImageURL(model)
      imageElement.src = url
      return url && url.length > 0
    },
    setupAvatarImage(imageElement, user) {
      if (
        user != null &&
        "avatarURL" in user &&
        user.avatarURL != null &&
        user.avatarURL.length > 0
      ) {
        imageElement.src = user.avatarURL
      } else if (
        user != null &&
        "avatarMD5" in user &&
        user.avatarMD5 != null &&
        user.avatarMD5.length > 0
      ) {
        imageElement.src =
          process.env.VUE_APP_API_BASEURL +
          "/api/v1/files/downloadAvatar?userID=" +
          user.id
      }
    },
    replaceAll(str, find, replace) {
      if (!str) return null

      return str.replace(new RegExp(find, "g"), replace)
    },
    linkify(inputText) {
      if (!inputText) return ""

      const pattern1 =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
      let text = inputText.replace(
        pattern1,
        '<a href="$1" target="_blank">$1</a>',
      )

      const pattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
      text = text.replace(
        pattern2,
        '$1<a href="http://$2" target="_blank">$2</a>',
      )
      return inputText
    },
    cleanString(str) {
      str = this.replaceAll(str, "<", "&lt;")
      str = this.replaceAll(str, ">", "&gt;")
      return str
    },
    fixDescriptionStringForHTML(str) {
      str = this.cleanString(str)
      str = this.replaceAll(str, "\n", "<br/>")
      str = this.linkify(str)
      return str
    },
    copyTextToClipboard(string) {
      // Desktops
      if (navigator.clipboard) {
        navigator.clipboard.writeText(string).then(
          function () { },
          function (err) {
            console.error("Async: Could not copy text: ", err)
          },
        )
      }
      // iOS
      let textarea
      let result

      try {
        textarea = document.createElement("textarea")
        textarea.setAttribute("readonly", true)
        textarea.setAttribute("contenteditable", true)
        textarea.style.position = "fixed" // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = string

        document.body.appendChild(textarea)

        textarea.focus()
        textarea.select()

        const range = document.createRange()
        range.selectNodeContents(textarea)

        const sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)

        textarea.setSelectionRange(0, textarea.value.length)
        result = document.execCommand("copy")
      } catch (err) {
        console.error(err)
        result = null
      } finally {
        document.body.removeChild(textarea)
      }

      // manual copy fallback using prompt
      if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
        const copyHotkey = isMac ? "⌘C" : "CTRL+C"
        result = prompt(`Press ${copyHotkey}`, string) // eslint-disable-line no-alert
        if (!result) {
          return false
        }
      }
      return true
    },
    disableScrollOutsideElement(topElement, name) {
      var wheelEvent =
        "onwheel" in document.createElement("div") ? "wheel" : "mousewheel"
      function preventDefault(e) {
        try {
          var delta = e.deltaY
          var up = delta < 0

          // find any scrollable element inside given topElement
          var canScrollAnywhereWithinTopElement = false
          var elementToCheck = e.target
          while (elementToCheck != null && elementToCheck != topElement) {
            // can it be scrolled at all?
            var overflow = getComputedStyle(elementToCheck)["overflow-y"]
            if (overflow == "scroll" || overflow == "auto") {
              if (up) {
                if (elementToCheck.scrollTop > 0) {
                  canScrollAnywhereWithinTopElement = true
                  break
                }
              } else {
                var maxScroll =
                  elementToCheck.scrollHeight - elementToCheck.clientHeight
                if (Math.ceil(elementToCheck.scrollTop) < maxScroll) {
                  canScrollAnywhereWithinTopElement = true
                  break
                }
              }
            }

            elementToCheck = elementToCheck.parentElement
          }
          if (!canScrollAnywhereWithinTopElement) {
            event.preventDefault()
          }
        } catch (e) {
          //
        }
      }
      topElement.addEventListener(wheelEvent, preventDefault, true)
    },
    convertColorXYZToHex(colorObject) {
      var result = "#"

      result += this.colorelementToHex(colorObject.x)
      result += this.colorelementToHex(colorObject.y)
      result += this.colorelementToHex(colorObject.z)

      return result
    },
    convertHexColorToXYZ(hex) {
      var r = hex.substring(1, 3)
      var g = hex.substring(3, 5)
      var b = hex.substring(5, 7)

      r = parseInt(r, 16)
      g = parseInt(g, 16)
      b = parseInt(b, 16)

      r /= 255.0
      g /= 255.0
      b /= 255.0

      return { x: r, y: g, z: b }
    },
    getCollectionLocale(collection) {
      let locale = this.getModelLocale(collection)
      if (locale == null && collection.systemName) {
        if (collection.systemName == "SCANNED") {
          locale = { name: "Scanned" }
        } else if (collection.systemName == "FAVOURITE") {
          locale = { name: "Favorites" }
        } else {
          console.error("Unhandled systemName: " + collection.systemName)
        }
      }

      return locale
    },
    getLocale(locales) {
      var userLang = (navigator.language || navigator.userLanguage).substring(
        0,
        2,
      )
      var localeId = 1 // en
      if (userLang == "ru") {
        localeId = 2
      }

      var locale = null
      var defaultLocale = null
      for (let i = 0; i < locales.length; i++) {
        var l = locales[i]
        if (l.cultureId == 1 /*en*/) {
          defaultLocale = l
        }
        if (l.cultureId == localeId) {
          locale = l
          break
        }
      }
      if (locale == null) {
        locale = defaultLocale
      }

      return locale
    },
    getModelLocale(model) {
      return this.getLocale(model.locales)
    },
    removeFileRecursively(file) {
      try {
        var stat = this.$editor.fs.stat(file)

        if (this.$editor.fs.isDir(stat.mode)) {
          let files = this.fsReadAllFiles(file)
          for (var i = 0; i < files.length; ++i) {
            let f = files[i]
            this.removeFileRecursively(f.path)
          }
          this.$editor.fs.rmdir(file)
        } else if (this.$editor.fs.isFile(stat.mode)) {
          this.$editor.fs.unlink(file)
        }
      } catch (err) {
        console.log(err)
      }
    },
    fsReadAllFiles(folder) {
      const files = []
      let _this = this

      function impl(curFolder) {
        for (const name of _this.$editor.fs.readdir(curFolder)) {
          if (name === "." || name === "..") continue

          const path = `${curFolder}/${name}`
          const { mode, timestamp } = _this.$editor.fs.lookupPath(path).node
          if (_this.$editor.fs.isFile(mode)) {
            files.push({ path, timestamp })
          } else if (_this.$editor.fs.isDir(mode)) {
            impl(path)
          }
        }
      }

      impl(folder)
      return files
    },
    hasElementParentWithId(element, id) {
      var parent = element
      while (parent) {
        if (parent.id == id) {
          return true
        }
        parent = parent.parentElement
      }

      return false
    },
    isChildOfParentElementDeep(elementToCheck, parentElement) {
      var parent = elementToCheck
      while (parent) {
        if (parent == parentElement) {
          return true
        }
        parent = parent.parentElement
      }

      return false
    },
    onVisible(element, callback) {
      new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            callback(element)
            observer.disconnect()
          }
        })
      }).observe(element)
    },
    getCommonHeaders: function () {
      var result = {}
      if (this.$store.state.token) {
        result["Authorization"] = "Bearer " + this.$store.state.token
      }
      result["platform"] = "website"
      return result
    },
    createSubscriptionItem(sub) {
      var ComponentClass = Vue.extend(StoreBundleItem)
      var item = new ComponentClass()
      item.loc = this.loc
      item.$mount() // pass nothing
      item.$root = this.$root // I don't know why $root doesn't match this one
      item.getCommonHeaders = this.getCommonHeaders
      item.purchaseTitle = this.$t("price-calculator.purchase-button")
      item.configure(sub)
      item.collapsable = true
      item.editableOptions = false
      item.collapse()

      return item
    },
    getArchwebURL(allowCdn /*unused*/) {
      let resourcesURL = process.env.VUE_APP_STATIC_URL
      return (
        process.env.VUE_APP_STATIC_URL +
        "/archweb.html?version=" +
        process.env.VUE_APP_ARCHWEB_VERSION +
        "&resourcesURL=" +
        resourcesURL +
        "&"
      )
    },
    loc(key) {
      return this.$t(key)
    },
    getWebsiteURL(allowCdn) {
      if (process.env.NODE_ENV == "production" && allowCdn) {
        return process.env.VUE_APP_WEBSITE_CDN
      } else {
        return process.env.VUE_APP_STATIC_URL
      }
    },
    formatNumber(num, digits) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const regexp = /\.0+$|(?:\.[0-9]*[1-9])0+$/;
      const item = lookup.findLast(item => num >= item.value);
      return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
    },
    humanFileSize(bytes, si = false, dp = 0) {
      const thresh = si ? 1000 : 1024

      if (Math.abs(bytes) < thresh) {
        return bytes + " B"
      }

      const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      )

      return bytes.toFixed(dp) + "" + units[u]
    },
    async showSceneMapPopup(scene) {
      const headers = this.getCommonHeaders()
      const { data } = await this.axios.get(
        process.env.VUE_APP_API_BASEURL + API_URL.MAP_PUBLIC,
        {
          headers: headers,
        },
      )

      const modelsList = data.filter(
        (item) => item.latitude !== 0 && item.longitude !== 0,
      )
      modelsList.push(scene)

      this.$root.showFullscreenPopup({
        item: Map,
        classes: "model-preview__popup",
        props: {
          center: { lat: scene.latitude, lng: scene.longitude },
          withMarkerEvent: false,
          modelsList,
          isMapShown: true,
          showSeparators: false
        }
      })
    },
    buildAnalyticsOptions(analytics) {
      let categories = []
      let data = []
      var max = 0
      for (let date in analytics) {
        let downloads = analytics[date]

        if (downloads > max) {
          max = downloads
        }

        categories.push(new Date(date).getTime())
        data.push(downloads)
      }

      var options = {
        chart: {
          //type: 'line',
          height: 160,
          type: 'area',
          stacked: false
        },
        series: [{
          name: 'downloads',
          data: data
        }],
        xaxis: {
          categories: categories,
          type: 'datetime'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 2,
          colors: ['black']
        },
        fill: {
          opacity: 0.5,
          colors: ['#111111']
        },
        yaxis: {
          max: max + 1,
          min: 0,
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          },
        },
        grid: {
          position: 'back'
        }
      }
      return options
    },
    showShareSceneWindow(hash) {
      this.$root.$emit("showShareWindow", hash)
    }
  },
})

const I18nPlugin = {
  install(Vue, options) {
    const _$t = Vue.prototype.$t
    Vue.prototype._$t = _$t

    Vue.prototype.$t = function () {
      if (this.$i18n) {
        return _$t.apply(this, arguments)
      } else {
        return _$t.apply(this.$root, arguments)
      }
    }
  },
}

Vue.use(I18nPlugin)

new Vue({
  i18n,
  router,
  store,
  vuetify: new Vuetify(),
  render: (h) => h(App)
}).$mount("#app")
