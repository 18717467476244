<template>
  <transition name="fast-transition">
    <div id="share-container" v-show="show">
      <div @click="onclickContainer()" id="share-overlay"></div>
      <div id="share-window">
        <div id="share-qr-header">
          {{ $t("share.description") }}
        </div>
        <div id="qr-img-container">
          <transition name="fast-transition">
            <img ref="qrImage" class="share-qr-img" crossorigin="anonymous" />
          </transition>
          <b-spinner id="share-qr-spinner" v-show="qrIsGenerating" variant="dark"></b-spinner>
        </div>
        <button v-if="imageCopyingAvailable" @click="onCopyQR()" class="default-nice-button" id="share-copy-qr-btn">
          <span id="share-copy-qr-btn-label" class="text-white">{{ copyQRButtonText }}</span>
        </button>
        <div id="share-link-header">
          {{ $t("share.description-or") }}
        </div>
        <div class="share-link-label" ref="linkLabel">

        </div>

        <button @click="onCopyLink()" class="default-nice-button" id="share-copy-link-btn">
          <span id="share-copy-link-btn-label" class="text-white">{{ copyLinkButtonText }}</span>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  data: function () {
    return {
      hash: "",
      copyLinkButtonText: "Copy Link",
      copyQRButtonText: "Copy QR",
      link: "",
      show: false,
      imageCopyingAvailable: true
    }
  },
  created() {

  },
  mounted() {
    this.imageCopyingAvailable = navigator.clipboard != null
    this.copyLinkButtonText = this.loc("share.copy-link")
    this.copyQRButtonText = this.loc("share.copy-qr")
  },
  methods: {
    showWindow(hash) {
      this.hash = hash;
      this.show = true;

      let link = this.generateSceneLink(this.hash, true);
      this.$refs.linkLabel.innerHTML = link;
      this.link = link;

      let qrImageElement = this.$refs.qrImage

      let _this = this;
      var url = ""
      if (process.env.NODE_ENV === "production") {
        url = process.env.VUE_APP_SERVER_CDN + "/sceneQR/" + this.hash
      } else {
        url = process.env.VUE_APP_API_BASEURL + "/api/v1/files/downloadQR?hash=" + this.hash
      }

      this.axios
        .get(url, {
          responseType: 'arraybuffer'
        })
        .then(function (response) {
          _this.qrData = response.data;
          var dataBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(response.data)));
          qrImageElement.src = "data:image/png;base64," + dataBase64;
        })
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL.replace(/^data:image\/?[A-z]*;base64,/);
    },
    onCopyQR() {
        let _this = this;
  
        try {
          navigator.clipboard.write([
            new ClipboardItem({
              'image/png': new Blob([new Uint8Array(this.qrData)], { type: "image/png" })
            })
          ]);
          let copyQRButtonLabelElement = document.getElementById("share-copy-qr-btn-label");
          copyQRButtonLabelElement.innerHTML = "Copied!";
  
          setTimeout(function() {
            copyQRButtonLabelElement.innerHTML = _this.copyQRButtonText;
          }, 500);
        } catch (error) {
            console.error(error);
        }
            
    },
    onCopyLink() {
      this.copyTextToClipboard(this.link);

      let copyLinkButtonLabelElement = document.getElementById("share-copy-link-btn-label");
      copyLinkButtonLabelElement.innerHTML = this.loc("share.copied")

      let _this = this;
      setTimeout(function () {
        copyLinkButtonLabelElement.innerHTML = _this.copyLinkButtonText;
      }, 500);
    },
    onclickContainer() {
      this.show = false;
    },
    updateQRImage() {
      this.qrIsGenerating = false;

      let qrImageElement = this.$refs.qrImage

      var data = this.$archengine.FS.readFile(this.qrPath, { encoding: 'binary' });
      var dataBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(data)));
      qrImageElement.src = "data:image/png;base64," + dataBase64;


      let link = this.generateSceneLink(this.hash, true);
      //link = eval(link);
      this.$refs.linkLabel.innerHTML = link;

      this.link = link;
      this.qrData = data;
    },
  }
}
</script>

<style lang="scss">
#share-container {
  width: 100%;
  height: 100%;
  z-index: 10;
}

#share-overlay {
  width: 100%;
  height: 100%;
  background-color: #00000085;
  position: fixed;
  top: 0px;
}

#share-window {
  width: 380px;
  padding-bottom: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #e9e9e9;
  border-radius: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
  text-align: center;
}

.share-qr-img {
  width: 100%;
  aspect-ratio: 1;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #e3e3e3;
}

#qr-img-container {
  margin: 10px 20px 0 20px;
  aspect-ratio: 1;
}

#share-qr-spinner {
  margin-top: 140px;
}

#share-qr-header {
  font-weight: 500;
  font-size: 17px;
  margin: 20px 10px 0 10px;
}

.share-link-label {
  margin-bottom: 15px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #fefefe;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  font-size: 13px;
}

.share-link-label:hover {
  background-color: #fafafa;
}

#share-copy-link-btn {
  width: 90%;
}

#share-copy-qr-btn {
  width: 90%;
  margin-top: 10px !important;
}

#share-link-header {
  font-weight: 500;
  font-size: 18px;
  margin: 15px 25px 10px 25px;
}
</style>