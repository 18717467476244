<template>
  <div class="scene-analytics-grid-wrapper">
    <div v-show="showGrid" ref="container" class="scene-analytics-grid-container"></div>

    <div
      v-if="scenesVisibleAmount == 0"
      ref="emptyContainer"
      class="scene-analytics-grid-container-empty"
      v-html="$t(noModelsText)"
    >

    </div>
  </div>
</template>

<script>

import Vue from "vue";
import SceneAnalyticsItem from "./SceneAnalyticsItem.vue";

export default {
  props: {
    noModelsText: {
      default: "analytics.no-models",
      type: String,
    },
    sceneArray: {
      default: null,
      type: Array,
    }
  },
  data: function () {
    return {
      itemList: [],
      showGrid: true,
      onSelectItem: null,
      scenesVisibleAmount: 0
    }
  },
  watch: {

  },
  mounted() {
    if (this.sceneArray) {
      this.configure(this.sceneArray)
    }
  },
  methods: {
    createSceneAnalyticsItem(scene) {
      var ComponentClass = Vue.extend(SceneAnalyticsItem)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.configure(scene)

      if (this.onSelectItem) {
        item.onSelectItem = this.onSelectItem
      }

      return item
    },
    setOnSelectItem(func) {
      this.onSelectItem = func

      for (let itemIndex in this.itemList) {
        let item = this.itemList[itemIndex]
        item.onSelectItem = func
      }
    },
    configure(sceneArray) {
      this.itemList = []
      this.$refs.container.innerHTML = ""

      this.scenesVisibleAmount = 0

      if (sceneArray != null && sceneArray.length > 0) {
        for (var index in sceneArray) {
          let scene = sceneArray[index]

          let item = this.createSceneAnalyticsItem(scene)

          this.$refs.container.appendChild(item.$el)

          this.itemList.push(item)
          ++this.scenesVisibleAmount
        }
      }

      this.showGrid = this.scenesVisibleAmount > 0
    }
  }
}
</script>

<style lang="scss">
.scene-analytics-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  grid-gap: 20px;
  justify-content: center;
}

.scene-analytics-grid-container-empty {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>
